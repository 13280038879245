import React from 'react';
import styled from 'styled-components';

import { colors, fontSize } from '../../../styles/variables';

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

const InputField = styled.input`
    border: none;
    padding: 0.5rem 0 0.5rem 1rem;
    outline: transparent 0;
    line-height: 1.6rem;
    width: 16rem;
    font-size: ${fontSize.base};

    :focus,
    :active {
        outline: transparent 0;
    }
`;

const ErrorMessage = styled.p`
    position: absolute;
    margin: 0;
    bottom: -2rem;
    color: ${colors.milanoRed};
    font-size: ${fontSize.small};
`;

export const Input = ({ field: { onChange, value, ...field }, form: { errors, touched }, ...props }) => {
    const errorMsg = touched[field.name] && errors[field.name];

    return (
        <InputWrapper>
            <InputField value={value} onChange={onChange} error={!!errorMsg} {...field} {...props} />

            {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        </InputWrapper>
    );
};
