export const s3Api = process.env.REACT_APP_S3_URL;
export let apiBase = process.env.REACT_APP_API_URL;
export const backendApiBase = process.env.REACT_APP_BACKEND_URL;
export let usedCdn = [];

/**
 * Base API url with error detecting
 */
export const fetchApi = (path = '/', params = {}) =>
    fetch(`${apiBase}${path}`, params).then(res => {
        if (!res.ok) throw res;
        return res.json();
    });

export const loadCdnList = () =>
    fetch('https://chopin2020.pl/cdnlist.json').then(res => {
        if (!res.ok) throw res;
        return res.json();
    });

export const drawApiBase = cdnList => {
    let cdnLeft = cdnList.filter(el => !usedCdn.includes(el));

    if (cdnLeft.length === 0) {
        usedCdn = [];
        cdnLeft = [...cdnList];
    }

    const index = Math.floor(Math.random() * cdnLeft.length);
    apiBase = 'https://' + cdnLeft[index];
    usedCdn.push(cdnLeft[index]);
    console.warn(apiBase);
};
