import dayjs from 'dayjs';
import * as R from 'ramda';

export const getWeekDay = date =>
    R.cond([
        [R.equals(0), R.always('sunday')],
        [R.equals(1), R.always('monday')],
        [R.equals(2), R.always('tuesday')],
        [R.equals(3), R.always('wednesday')],
        [R.equals(4), R.always('thursday')],
        [R.equals(5), R.always('friday')],
        [R.equals(6), R.always('saturday')],
        [R.T, R.always('none')],
    ])(dayjs(date).day());
