import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { SectionTitle } from '../common/Titles';
import { NewsletterForm } from '../common/newsletter/NewsletterForm';
import { NewsletterPopUp } from '../common/newsletter/NewsletterPopUp';
import { colors, mediaQueries, fontSize, lineSpacing, fontFamily } from '../../styles/variables';

import { appIcon, googleIcon } from '../../static/configs/icons';
import { mobileImage } from '../../static/configs/images';
import { getThumbnailImage } from '../../helpers';

const SectionWrapper = styled.footer`
    box-sizing: border-box;
    position: relative;
    background-color: ${colors.codGray};
`;

const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;

    color: ${colors.white};

    padding: 1.5rem;

    ${mediaQueries.sm} {
        flex-direction: row;
        padding: 4rem 3rem 3rem;
    }

    ${mediaQueries.md} {
        padding: 5rem 5rem 4rem;
    }

    ${mediaQueries.xxl} {
        padding: 6rem 8rem 5rem;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    font-size: ${fontSize.mediumExtended};
    font-family: ${fontFamily.openSansSemiBold};
    line-height: ${lineSpacing.extend};
`;

const ApplicationContainer = styled(FlexContainer)`
    padding-top: 2rem;
    z-index: 1;

    ${mediaQueries.sm} {
        padding-top: 0;
        padding-left: 3rem;
        border-left: 1px ${colors.white} solid;
        width: 50%;
        padding-left: 1.5rem;
    }

    ${mediaQueries.lg} {
        width: 30%;
    }
`;

const MobileContainer = styled.div`
    display: none;
    position: absolute;
    bottom: 0;

    justify-content: flex-end;
    z-index: 0;

    border-bottom: 1px ${colors.doveGray} solid;

    ${mediaQueries.sm} {
        width: calc(100% - 6rem);
    }

    ${mediaQueries.lg} {
        display: flex;
        width: calc(100% - 10rem);
    }

    ${mediaQueries.xxl} {
        width: calc(100% - 16rem);
    }
`;

const TextWrapper = styled.span`
    padding: 1rem 0 2rem;

    ${mediaQueries.lg} {
        width: ${props => props.width};
    }

    ${mediaQueries.xl} {
        width: auto;
    }
`;

const Image = styled.img`
    width: 9rem;
`;

const ImageGoogle = styled(Image)`
    padding-top: 1rem;
`;

const MobileImage = styled.img`
    width: 15rem;
    height: 100%;
`;

const NewsletterFooterContainer = ({ t }) => {
    const [isActive, setIsActive] = React.useState(false);

    return (
        <SectionWrapper>
            <ContentWrapper>
                <NewsletterForm t={t} handleClick={() => setIsActive(true)} />
                {isActive && <NewsletterPopUp handleClick={() => setIsActive(false)} />}

                <ApplicationContainer>
                    <SectionTitle title={t('newsletter title app')} color={colors.white} margin="0" />
                    <TextWrapper width="13rem">{t('newsletter app')}</TextWrapper>
                    <a
                        href="https://apps.apple.com/app/chopin-competition-2020/id1499789385"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Image alt="app store" src={appIcon} />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=pl.nifc.chopincompetition2020"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <ImageGoogle alt="google play" src={googleIcon} />
                    </a>
                </ApplicationContainer>

                <MobileContainer>
                    <MobileImage src={getThumbnailImage(mobileImage)} alt="" />
                </MobileContainer>
            </ContentWrapper>
        </SectionWrapper>
    );
};

export const NewsletterFooter = withTranslation('home')(NewsletterFooterContainer);
