import { fetchApi } from "../../api/apiBase";

export const contactsServiceFactory = () => {
    const getContactsByIds = ({ ids }) => {
        const links = ids.map(id => fetchApi(`/contacts/${id}`));

        return Promise.all(links);
    }

    const getContacts = () => fetchApi(`/contacts`);

    return {
        getContactsByIds,
        getContacts
    }
};
