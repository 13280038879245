import { fetchApi } from "../../api/apiBase";

export const multimediaCompetitionServiceFactory = () => {
    const getCompetitionGallery = () => fetchApi(`/multimediagalleries`);

    const getCompetitionGalleryById = ({ id }) =>
        fetchApi(`/multimediagalleries?id=${id}`);

    const getCompetitionGroup = () =>
        fetchApi(`/multimediagroups?_limit=-1`);

    const getCompetitionPhotos = () =>
        fetchApi(`/multimediaphotos?_limit=-1`);

    const getCompetitionAudios = () =>
        fetchApi(`/multimediaaudios?_limit=-1`);

    const getCompetitionVideos = () =>
        fetchApi(`/multimediavideos?_limit=-1`);

    return {
        getCompetitionGallery,
        getCompetitionGalleryById,
        getCompetitionGroup,
        getCompetitionPhotos,
        getCompetitionAudios,
        getCompetitionVideos,
    };
};
