import { fetchApi } from '../../api/apiBase';

export const articleServiceFactory = () => {
    const getArticle = ({ id }) => fetchApi(`/news/${id}`);

    const getTopArticles = ({ limit }) =>
        fetchApi(
            `/news?&is_published=true&_sort=hot_indicator:DESC&_limit=${limit}&hot_indicator_null=false`,
        );

    const getNewestArticles = ({ limit }) => {
        if (limit === null) {
            return fetchApi(`/news?&is_published=true&_sort=visible_date:DESC`);
        }
        return fetchApi(`/news?&is_published=true&_sort=visible_date:DESC&_limit=${limit}`);
    };
    return {
        getArticle,
        getTopArticles,
        getNewestArticles,
    };
};
