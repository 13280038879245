import { s3Api } from '../../api/apiBase';

export const aboutUsCompetition = `${s3Api}/images/aboutUs_competition.jpg`;
export const aboutUsContact = `${s3Api}/images/aboutUs_contact.jpg`;
export const aboutUsOrganizer = `${s3Api}/images/aboutUs_organizer.jpg`;
export const aboutUsPress = `${s3Api}/images/aboutUs_press.jpg`;
export const aboutUsTickets = `${s3Api}/images/aboutUs_tickets.jpg`;
export const communityImage = `${s3Api}/images/v2_community.png`;
export const competitionTitle = `${s3Api}/images/competition-title.png`;
export const competitionLogo = `${s3Api}/images/competition-logo.png`;
export const compositionDefaultImg = `${s3Api}/images/compositions.png`;
export const compositionImage = `${s3Api}/images/composition.png`;
export const exhibitionIcon = `${s3Api}/images/v2_exhibition.png`;
export const headerPhoto = `${s3Api}/images/header-photo-new.jpg`;
export const mobileImage = `${s3Api}/images/v4_mobile.png`;
export const newsletterBackground = `${s3Api}/images/v2_newsletter.png`;
export const poster = `${s3Api}/images/v2_footer_poster.png`;
export const chopinMusicSources = `${s3Api}/images/dziedzictwo-chopinowskie.svg`;
export const photoBackgroundDefault = `${s3Api}/images/photoBackgroundDefault.png`;
