import i18n from '../../i18n';
import { routes } from '../../routes/index';

export const accreditationServiceFactory = (apiBase, fetch) => {
    const { language } = i18n;

    const submitAccreditation = (data, setIsSubmitting, t, history) =>
        fetch(`${apiBase}/accreditation`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(data),
        }).then(res => {
            if (res.ok) {
                history.push(`/${language}${routes.accreditationSuccess}`);
            } else {
                alert(t('error'));
                setIsSubmitting(false);
            }
        });

    return { submitAccreditation };
};
