import React from 'react';

const getScrollPosition = () => {
    if (typeof window === 'undefined') return { y: 0 };

    const position = document.body.getBoundingClientRect();

    return { y: position.top };
};

export const useScrollPosition = (condition, listener, timeout) => {
    let throttleTimeout = null;

    const callBack = () => {
        const currentPosition = getScrollPosition();
        condition(currentPosition);
        throttleTimeout = null;
    };

    React.useLayoutEffect(() => {
        const handleScroll = () => {
            if (timeout) {
                if (throttleTimeout === null) {
                    throttleTimeout = setTimeout(callBack, timeout); // eslint-disable-line
                }
            } else {
                callBack();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            throttleTimeout && clearTimeout(throttleTimeout);
        };
    }, listener);
};
