import * as R from 'ramda';

import { apiBase, s3Api } from '../../api/apiBase';
import { compositionImage } from '../../static/configs/images';

const amazonUrl = 'media.chopin2020.pl/';
const mediaUrl = 'https://media.chopin2020.pl';

export const fileUrl = url => (R.test(/^http/, url) ? url : apiBase + url);

export const getThumbnailImage = (url, size = 450) => {
    const pathArray = url
        .replace(s3Api, `${mediaUrl}/assets`)
        .replace(amazonUrl, '')
        .split('/')
        .slice(3);

    return [mediaUrl, 'fit-in', `${size}x${size}`, ...pathArray].join('/');
};

export const getImageSource = item => {
    const photoUrlOrDefaultImage = item.photo ? item.photo.url : compositionImage;
    const thumbnailImage = getThumbnailImage(fileUrl(photoUrlOrDefaultImage), 600);

    return item.image_url ? item.image_url : thumbnailImage;
};
