import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Raven from 'raven-js';

import * as serviceWorker from './serviceWorker';
import { App } from './App';
import './i18n';

ReactGA.initialize('GTM-NBGMVZQ');
ReactGA.pageview(window.location.pathname + window.location.search);

const sentry = process.env.REACT_APP_SENTRY;

if (sentry) {
    Raven.config(sentry).install();
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
