import React from 'react';
import styled from 'styled-components';
import { useHistory, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { useAuth } from '../../helpers/auth/Context';
import { fontSize, mediaQueries, colors } from '../../styles/variables';
import { routes } from '../../routes/index';
import { logout } from '../../helpers/auth';
import { BlackButton, Button } from '../common/Buttons';
import { useOutsideCloser } from '../../hooks/useOutsideCloser';

import {
    flagIconSmall,
    flagIconTitle,
    arrowDropdown,
    arrowDropdownBlack,
} from '../../static/configs/icons';
import { competitionTitle } from '../../static/configs/images';

const Wrapper = styled.main`
    display: none;
    position: relative;
    font-size: ${fontSize.normal};

    ${mediaQueries.lg} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5rem;
    }

    ${mediaQueries.xl} {
        font-size: ${fontSize.normalExtended};
        padding: 0 7rem;
    }
`;

const FlagContainer = styled.div`
    position: relative;
    height: ${props => (props.isScrolled ? '5rem;' : '6rem;')};
    width: 7rem;
    transform: ${props => (props.isScrolled ? 'scale(0.5);' : 'scale(1);')};
    transition: all 0.4s linear;
`;

const FlagIcon = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
`;

const TitleIcon = styled.img`
    position: absolute;
    width: 4.5rem;
    bottom: -15%;
    right: -30%;

    transition: ${props => (props.isScrolled ? 'opacity 0.2s linear' : 'opacity 0.2s 0.2s linear')};
    opacity: ${props => (props.isScrolled ? '0' : '1')};
`;

const Title = styled.img`
    display: none;
    width: 25rem;
    transform: ${props =>
        props.isScrolled ? 'translateX(-50%) scale(0.6);' : 'translateX(-50%) scale(1);'};

    cursor: pointer;
    left: 50%;
    transition: transform 0.4s linear;

    ${mediaQueries.lg} {
        display: inline-block;
        position: absolute;
    }

    ${mediaQueries.xl} {
        transform: ${props =>
            props.isScrolled ? 'translateX(-50%) scale(0.7);' : 'translateX(-50%) scale(1);'};
    }
`;

const Section = styled.section`
    display: flex;
    align-items: center;
    margin-left: 0;
    justify-self: flex-end;

    ${props =>
        props.isScrolled
            ? 'opacity:0; transition: opacity 0.2s'
            : 'opacity:1; transition: opacity 0.2s 0.2s'};
`;

const AuthSection = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    font-size: ${fontSize.small};
`;

const AuthButton = styled.button`
    color: ${colors.silverChalice};
    background-color: transparent;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-size: ${fontSize.small};

    outline: transparent 0;

    :focus,
    :active {
        outline: transparent 0;
    }
`;

const NameWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 6.5rem;
    height: 3rem;
`;

const AuthName = styled.span`
    color: ${colors.white};
    text-align: center;
`;

const AuthArrow = styled.img`
    cursor: pointer;
    margin: 0 1rem;
    ${props => props.isRotate && `transform: rotate(180deg)`};
`;

const AuthDropdown = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: -0.5rem;
    right: -2.75rem;

    color: ${colors.black};
    background-color: ${colors.white};
    box-shadow: 0 3px 3px ${colors.silverChalice};

    width: 10rem;
    padding: 0.5rem 1rem 1rem;
    z-index: 4;
    text-align: center;

    ${AuthName} {
        color: ${colors.black};
    }
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.mineShaft};
`;

const SettingsButton = styled(Button)`
    color: ${colors.black};
    background-color: transparent;
    padding: 1rem 0;
`;

const MainSectionContainer = ({ isScrolled, t, i18n }) => {
    const [isDropdown, setIsDropdown] = React.useState(false);

    const { push } = useHistory();
    const { userData, setIsAuthenticated } = useAuth();

    const ref = React.useRef();
    useOutsideCloser(ref, () => setIsDropdown(false));

    const logoutUser = () => {
        logout();
        setIsAuthenticated(false);
    };

    React.useEffect(() => {
        isScrolled && setIsDropdown(false);
    }, [isScrolled]);

    return (
        <Wrapper isScrolled={isScrolled}>
            <FlagContainer isScrolled={isScrolled}>
                <a href="https://nifc.pl/" target="_blank" rel="noreferrer noopener">
                    <FlagIcon src={flagIconSmall} alt="" aria-hidden="true" isScrolled={isScrolled} />
                    <TitleIcon src={flagIconTitle} alt="" aria-hidden="true" isScrolled={isScrolled} />
                </a>
            </FlagContainer>
            <Title
                onClick={() => push(`/${i18n.language}${routes.home}`)}
                src={competitionTitle}
                isScrolled={isScrolled}
            />

            <Section isScrolled={isScrolled}>
                <AuthSection>
                    {userData && !userData.error ? (
                        <>
                            <NameWrapper>
                                <AuthName>{userData.name}</AuthName>
                                <AuthArrow
                                    src={arrowDropdown}
                                    onClick={() => setIsDropdown(!isDropdown)}
                                />
                            </NameWrapper>
                            {isDropdown && (
                                <AuthDropdown ref={ref}>
                                    <NameWrapper>
                                        <AuthName>{userData.name}</AuthName>
                                        <AuthArrow
                                            isRotate
                                            src={arrowDropdownBlack}
                                            onClick={() => setIsDropdown(!isDropdown)}
                                        />
                                    </NameWrapper>

                                    <Line />
                                    <Link
                                        to={`/${i18n.language}${routes.profile}`}
                                        onClick={() => setIsDropdown(false)}
                                    >
                                        <SettingsButton>{t('settings')}</SettingsButton>
                                    </Link>
                                    <BlackButton onClick={() => logoutUser()} padding="1rem 2rem">
                                        {t('logout')}
                                    </BlackButton>
                                </AuthDropdown>
                            )}
                        </>
                    ) : (
                        <Link to={`/${i18n.language}${routes.login}`}>
                            <AuthButton>
                                {t('login')}/{t('register')}
                            </AuthButton>
                        </Link>
                    )}
                </AuthSection>
            </Section>
        </Wrapper>
    );
};

export const MainSection = withTranslation('layout')(MainSectionContainer);
