import React from 'react';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { colors, mediaQueries } from '../../styles/variables';
import { Section } from '../common/Section';
import { footerLogos } from './data';

const Wrapper = styled.footer`
    display: flex;
`;

const Logos = styled(Section)`
    width: 100%;
    background-color: ${colors.white};

    ${mediaQueries.md} {
        align-items: center;
        justify-content: space-around;
        flex-direction: row;
        padding: 3rem;
    }

    ${mediaQueries.xl} {
        justify-content: space-between;
    }

    ${mediaQueries.xxxl} {
        width: 1600px;
    }
`;

const Logo = styled.img`
    width: ${props => `${props.width}rem`};
    margin-bottom: 3rem;

    ${mediaQueries.md} {
        margin-bottom: 0;
    }
`;

const LogosFooterContainer = ({ i18n }) => (
    <Wrapper>
        <Logos>
            {footerLogos.map(icon => (
                <Logo
                    key={icon.name}
                    width={icon.width}
                    src={i18n.language === 'pl' ? icon.pl : icon.en}
                    alt=""
                    aria-hidden="true"
                />
            ))}
        </Logos>
    </Wrapper>
);

export const LogosFooter = withTranslation('layout')(LogosFooterContainer);
