import React from 'react';
import { useMountedState } from './useMountedState';

export const useGetPromise = (getPromise, defaultValue, isScroll) => {
    const [state, setState] = React.useState({ value: defaultValue, error: null, isLoaded: false });

    React.useEffect(() => {
        let isDidMount = true;
        const promise = getPromise();
        isScroll && window.scrollTo(0, 0);
        promise &&
            promise
                .then(value => {
                    isDidMount && setState({ value, error: null, isLoaded: true });
                })
                .catch(error => isDidMount && setState({ value: null, error: error, isLoaded: true }));

        return () => {
            isDidMount = false;
        };
    }, [getPromise]); // eslint-disable-line

    const { value, isLoaded, error } = state;
    return [value, isLoaded, error];
};

export const useSafePromise = () => {
    const isMounted = useMountedState();

    return React.useCallback(
        (promise, defaultValue) =>
            new Promise((resolve, reject) => {
                const onValue = value => {
                    isMounted() && resolve(value);
                };
                const onError = () => {
                    isMounted() && reject(defaultValue);
                };
                promise.then(onValue, onError);
            }),
        [], // eslint-disable-line
    );
};

export const useGetPromiseAndSetState = (getPromise, setState, errorValue, isScroll) => {
    React.useEffect(() => {
        let isMounted = true;
        const promise = getPromise();
        isScroll && window.scrollTo(0, 0);
        promise &&
            promise
                .then(res => isMounted && setState(res))
                .catch(() => isMounted && setState(errorValue));

        return () => (isMounted = false);
    }, [getPromise]); // eslint-disable-line
};
