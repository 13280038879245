export const commentServiceFactory = (apiBase, fetch) => {
    const getCommentParticipant = ({ id }) =>
        fetch(`${apiBase}/comment/participant/${id}`)
            .then(res => res.json())
            .catch(er => Promise.reject(new Error(er)));

    const postCommentParticipant = (token, participantId, text) =>
        fetch(`${apiBase}/comment/participant`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({ participantId, text }),
        }).then(res => !res.ok && res.json());

    const getCommentMultimedia = (id, category) =>
        fetch(`${apiBase}/comment/${category}/${id}`)
            .then(res => res.json())
            .catch(er => Promise.reject(new Error(er)));

    const postCommentMultimedia = (token, values, category) =>
        fetch(`${apiBase}/comment/${category}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(values),
        });

    return {
        getCommentParticipant,
        postCommentParticipant,
        getCommentMultimedia,
        postCommentMultimedia,
    };
};
