export const likeServiceFactory = (apiBase, fetch) => {
    const header = token => ({
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
    });

    const getLike = (id, category) =>
        fetch(`${apiBase}/like/${category}/${id}`)
            .then(res => res.json())
            .catch(er => Promise.reject(new Error(er)));

    const postLike = (token, id, category) =>
        fetch(`${apiBase}/like/${category}`, {
            headers: header(token),
            method: 'POST',
            body: JSON.stringify(id),
        });

    const deleteLike = (token, id, category) =>
        fetch(`${apiBase}/like/${category}/${id}`, {
            headers: header(token),
            method: 'DELETE',
        });

    const getUserLikes = token =>
        fetch(`${apiBase}/like/userLikes`, {
            headers: header(token),
            method: 'GET',
        }).then(res => res.json());

    return {
        getLike,
        postLike,
        deleteLike,
        getUserLikes,
    };
};
