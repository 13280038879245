import React from 'react';

export const useOutsideCloser = (ref, handler) => {
    React.useEffect(() => {
        const handleClick = event => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            handler(event);
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [handler, ref]);
};
