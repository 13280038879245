import { fetchApi } from '../../api/apiBase';

export const aboutUsServiceFactory = () => {
    const getAboutUsCompetition = () => fetchApi(`/aboutcompetitions`);

    const getAboutUsOrganizer = () => fetchApi(`/aboutorganizers`);

    const getCompetitionRules = () => fetchApi(`/competitionrules`);

    const getPressOffices = () => fetchApi(`/aboutpresses`);

    const getAccreditation = () => fetchApi(`/aboutpressaccreditations`);

    return {
        getAboutUsCompetition,
        getAboutUsOrganizer,
        getCompetitionRules,
        getPressOffices,
        getAccreditation,
    };
};
