import React from 'react';
import styled from 'styled-components';

import { TopFooter } from './TopFooter';
import { BottomFooter } from './BottomFooter';
import { mediaQueries } from '../../styles/variables';

const Wrapper = styled.footer`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${mediaQueries.lg} {
        transform: ${props => (props.isScrolled ? 'translateY(8rem);' : 'translateY(16rem)')};
        transition: transform 0.5s ease 0s;
    }
`;

export const Footer = ({ isScrolled }) => (
    <Wrapper isScrolled={isScrolled}>
        <TopFooter />
        <BottomFooter />
    </Wrapper>
);
