import React from 'react';
import styled from 'styled-components';
import 'animate.css/animate.css';
import { createGlobalStyle } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { useScrollPosition } from '../hooks/useScroll';
import { colors, fontFamily, mediaQueries } from '../styles/variables';

import { Header } from '../components/header/Header';
import { Footer } from '../components/footer/Footer';

import {
    alegreyaRegularWoff2,
    alegreyaRegularWoff,
    alegreyaRegularTtf,
    alegreyaRegularSvg,
    alegreyaRegularEot,
    alegreyaBoldWoff2,
    alegreyaBoldWoff,
    alegreyaBoldTtf,
    alegreyaBoldSvg,
    alegreyaBoldEot,
    alegreyaExtraBoldWoff2,
    alegreyaExtraBoldWoff,
    alegreyaExtraBoldTtf,
    alegreyaExtraBoldSvg,
    alegreyaExtraBoldEot,
    openSansRegularWoff2,
    openSansRegularWoff,
    openSansRegularTtf,
    openSansRegularEot,
    openSansSemiBoldWoff2,
    openSansSemiBoldWoff,
    openSansSemiBoldTtf,
    openSansSemiBoldSvg,
    openSansSemiBoldEot,
    openSansBoldWoff2,
    openSansBoldWoff,
    openSansBoldTtf,
    openSansBoldSvg,
    openSansBoldEot,
} from '../static/configs/fonts';

const getPaddingValueDependingOnScrollPositionAndBarVisibility = ({
    isScrolled,
    isStreamBarVisible,
}) => {
    const getPaddingValueDependingOnBarVisibility = isStreamBarVisible
        ? 'translateY(17rem);'
        : 'translateY(13rem);';

    return isScrolled ? 'translateY(8rem);' : getPaddingValueDependingOnBarVisibility;
};

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Alegreya Regular';
  font-style: normal;
  font-weight: 400;
  src: url(${alegreyaRegularEot}); /* IE9 Compat Modes */
  src: url(${alegreyaRegularWoff2}) format('woff2'), /* Super Modern Browsers */
       url(${alegreyaRegularWoff}) format('woff'), /* Modern Browsers */
       url(${alegreyaRegularTtf}) format('truetype'), /* Safari, Android, iOS */
       url(${alegreyaRegularSvg}) format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Alegreya Bold';
  font-style: normal;
  font-weight: 700;
  src: url(${alegreyaBoldEot});
  src: url(${alegreyaBoldWoff2}) format('woff2'),
       url(${alegreyaBoldWoff}) format('woff'), 
       url(${alegreyaBoldTtf}) format('truetype'), 
       url(${alegreyaBoldSvg}) format('svg');
}

@font-face {
  font-family: 'Alegreya ExtraBold';
  font-style: normal;
  font-weight: 800;
  src: url(${alegreyaExtraBoldEot});
  src: url(${alegreyaExtraBoldWoff2}) format('woff2'),
       url(${alegreyaExtraBoldWoff}) format('woff'),
       url(${alegreyaExtraBoldTtf}) format('truetype'), 
       url(${alegreyaExtraBoldSvg}) format('svg');
}

@font-face {
  font-family: 'OpenSans Regular';
  font-style: normal;
  font-weight: 400;
  src: url(${openSansRegularEot}); 
  src: url(${openSansRegularWoff2}) format('woff2'), 
       url(${openSansRegularWoff}) format('woff'), 
       url(${openSansRegularTtf}) format('truetype'),
       url(${openSansSemiBoldSvg}) format('svg'); 
}

@font-face {
  font-family: 'OpenSans SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url(${openSansSemiBoldEot}); 
  src: url(${openSansSemiBoldWoff2}) format('woff2'), 
       url(${openSansSemiBoldWoff}) format('woff'), 
       url(${openSansSemiBoldTtf}) format('truetype'), 
       url(${openSansSemiBoldSvg}) format('svg'); 
}

@font-face {
  font-family: 'OpenSans Bold';
  font-style: normal;
  font-weight: 700;
  src: url(${openSansBoldEot}); 
  src: url(${openSansBoldWoff2}) format('woff2'), 
       url(${openSansBoldWoff}) format('woff'), 
       url(${openSansBoldTtf}) format('truetype'), 
       url(${openSansBoldSvg}) format('svg'); 
}
 
    body {
        margin: 0;
        font-family: ${fontFamily.openSans};
        color: ${colors.black};
        background-color: ${colors.white};
        text-rendering: optimizeLegibility;

        a {
            color: ${colors.milanoRed};
            text-decoration: none;
        }
    }
`;

const Wrapper = styled.div`
    ${mediaQueries.lg} {
        transform: ${props => getPaddingValueDependingOnScrollPositionAndBarVisibility(props)};
    }

    transition: transform 0.2s linear;
`;

const LayoutContainer = ({ children, t }) => {
    const [isScrolled, setIsScrolled] = React.useState(false);
    const [isStreamBarVisible, setIsStreamBarVisible] = React.useState(false);

    useScrollPosition(
        currentPosition => {
            const isWinner = currentPosition.y < 0;
            isWinner !== isScrolled && setIsScrolled(isWinner);
        },
        [isScrolled],
        100,
    );

    return (
        <>
            <GlobalStyle />
            <Helmet>
                <title> {t('title')}</title>
            </Helmet>
            <Header
                isScrolled={isScrolled}
                handleStreamBarVisibility={setIsStreamBarVisible}
                isStreamBarVisible={isStreamBarVisible}
            />
            <Wrapper isScrolled={isScrolled} isStreamBarVisible={isStreamBarVisible}>
                {children}
            </Wrapper>
            <Footer isScrolled={isScrolled} />
        </>
    );
};

export const Layout = withTranslation('layout')(LayoutContainer);
