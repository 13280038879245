import React from 'react';
import styled, { css } from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import * as R from 'ramda';

import { withTranslation } from 'react-i18next';
import { translateDynamic } from '../../helpers';
import { facebookLink, instagramLink, twitterLink, navigationLinks } from '../../routes/links';
import { colors, fontSize, mediaQueries, fontFamily } from '../../styles/variables';
import { Icon } from '../common/Icon';

import { facebookIcon, instaIcon, twitterIcon, heartIcon } from '../../static/configs/icons';
import { routes } from '../../routes';

const Wrapper = styled.nav`
    display: none;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem 5rem;
    color: ${colors.twine};

    font-size: ${fontSize.xSmall};

    transition: padding 0.4s linear;

    ${mediaQueries.lg} {
        display: flex;
        flex-direction: column;
    }

    ${mediaQueries.xl} {
        padding: 0.5rem 7rem;
        font-size: ${fontSize.normal};
    }

    ${mediaQueries.xxxl} {
        width: calc(1600px - 14rem);
    }
`;

const NavigationItems = styled.ul`
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding-top: ${props => (props.isScrolled ? '0' : '1.5rem')};
    width: 100%;

    transition: padding-top 0.4s linear;
`;

const StyledLink = styled.li`
    position: relative;
    text-decoration: none;
    padding: 0.5rem;
    color: ${colors.white};
    font-family: ${fontFamily.openSansSemiBold};
    line-height: normal;
    cursor: pointer;
    text-align: center;
    transition: color 0.15s ease-out;
    list-style: none;

    a {
        color: ${colors.white};
    }

    :hover {
        color: ${colors.doveGray};

        a {
            color: ${colors.doveGray};
        }
    }

    :after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.5rem;
        background-color: ${colors.milanoRed};
        width: ${props => (props.isActive ? '100%' : '0')};
        height: 7px;
    }
`;

const DropdownList = styled.div`
    position: absolute;

    display: flex;
    flex-direction: column;
    background-color: ${colors.codGray};
    padding: 1.6rem 0 0.5rem;
    transform: scaleY(0);
    transform-origin: left 0;
    width: 100%;
    min-width: 9rem;
    transition: transform 0.2s ease-in;

    ${StyledLink} {
        width: initial;
        padding: 0.5rem 0.8rem;
    }

    :hover {
        transform: scaleY(1);
    }

    ${mediaQueries.xl} {
        padding-top: 1rem;
    }
`;

const DropdownContainer = styled.div`
    position: relative;
    text-align: center;

    :hover {
        ${DropdownList} {
            transform: scaleY(1);
        }
    }
`;

const ScrolledComponent = css`
    height: 0;
    opacity: 0;
    transform: scale(0);
`;

const Icons = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    width: 7.5rem;
    height: 1rem;

    ${props => props.isScrolled && ScrolledComponent};
    ${props =>
        props.isScrolled
            ? 'transition: opacity 0.2s, transform 0.4s;'
            : 'transition: opacity 0.2s 0.2s, transform 0.2s 0.2s;'}
`;

const getPathname = R.pipe(R.split('/'), R.tail);

const NavigationContainer = withRouter(({ location, history, t, i18n, isScrolled }) => {
    const translate = translateDynamic(i18n);

    const iconLinks = [
        { url: routes.favorite, icon: heartIcon, iconAlt: 'favorite', isExternal: false },
        { url: facebookLink, icon: facebookIcon, iconAlt: 'facebook', isExternal: true },
        { url: instagramLink, icon: instaIcon, iconAlt: 'instagram', isExternal: true },
        { url: twitterLink, icon: twitterIcon, iconAlt: 'twitter', isExternal: true },
    ];

    return (
        <Wrapper isScrolled={isScrolled}>
            <Icons isScrolled={isScrolled}>
                {iconLinks.map(link => (
                    <React.Fragment key={link.icon}>
                        {link.isExternal ? (
                            <a href={link.url} target="_blank" rel="noreferrer noopener">
                                <Icon src={link.icon} alt={t(`iconAlts.${link.iconAlt}`)} />
                            </a>
                        ) : (
                            <Link to={`/${i18n.language}${link.url}`}>
                                <Icon src={link.icon} alt={t(`iconAlts.${link.iconAlt}`)} />
                            </Link>
                        )}
                    </React.Fragment>
                ))}
            </Icons>
            <NavigationItems isScrolled={isScrolled}>
                {navigationLinks.map(link =>
                    link.isExternal ? (
                        <StyledLink key={link.name} isScrolled={isScrolled}>
                            <a
                                href={translate(link.hrefEN, link.hrefPL)}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t(`navigationItems.${link.name}`)}
                            </a>
                        </StyledLink>
                    ) : link.isDropdown ? (
                        <DropdownContainer key={link.name}>
                            <StyledLink
                                isActive={R.includes(link.name, getPathname(location.pathname))}
                                isScrolled={isScrolled}
                            >
                                {t(`navigationItems.${link.name}`)}
                            </StyledLink>
                            <DropdownList>
                                {link.dropdown.map(item => (
                                    <StyledLink
                                        key={item.href}
                                        onClick={() => history.push(`/${i18n.language}${item.href}`)}
                                        isScrolled={isScrolled}
                                    >
                                        <Link to={`/${i18n.language}${item.href}`}>
                                            {t(`navigationItems.dropdown.${item.name}`)}
                                        </Link>
                                    </StyledLink>
                                ))}
                            </DropdownList>
                        </DropdownContainer>
                    ) : (
                        <StyledLink
                            key={link.name}
                            onClick={() => history.push(`/${i18n.language}${link.href}`)}
                            isActive={
                                link.name === 'home'
                                    ? location.pathname === `/${i18n.language}${link.href}`
                                    : R.includes(link.name, getPathname(location.pathname))
                            }
                            isScrolled={isScrolled}
                        >
                            <Link to={`/${i18n.language}${link.href}`}>
                                {t(`navigationItems.${link.name}`)}
                            </Link>
                        </StyledLink>
                    ),
                )}
            </NavigationItems>
        </Wrapper>
    );
});

export const Navigation = withTranslation('layout')(NavigationContainer);
