import { s3Api } from '../../api/apiBase';

export const alegreyaRegularWoff2 = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-regular.woff2`;
export const alegreyaRegularWoff = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-regular.woff`;
export const alegreyaRegularTtf = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-regular.ttf`;
export const alegreyaRegularSvg = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-regular.svg`;
export const alegreyaRegularEot = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-regular.eot`;

export const alegreyaBoldWoff2 = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-700.woff2`;
export const alegreyaBoldWoff = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-700.woff`;
export const alegreyaBoldTtf = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-700.ttf`;
export const alegreyaBoldSvg = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-700.svg`;
export const alegreyaBoldEot = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-700.eot`;

export const alegreyaExtraBoldWoff2 = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-800.woff2`;
export const alegreyaExtraBoldWoff = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-800.woff`;
export const alegreyaExtraBoldTtf = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-800.ttf`;
export const alegreyaExtraBoldSvg = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-800.svg`;
export const alegreyaExtraBoldEot = `${s3Api}/fonts/alegreya/alegreya-v13-latin-ext_latin-800.eot`;

export const openSansRegularWoff2 = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-regular.woff2`;
export const openSansRegularWoff = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-regular.woff`;
export const openSansRegularTtf = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-regular.ttf`;
export const openSansRegularSvg = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-regular.svg`;
export const openSansRegularEot = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-regular.eot`;

export const openSansSemiBoldWoff2 = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-600.woff2`;
export const openSansSemiBoldWoff = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-600.woff`;
export const openSansSemiBoldTtf = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-600.ttf`;
export const openSansSemiBoldSvg = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-600.svg`;
export const openSansSemiBoldEot = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-600.eot`;

export const openSansBoldWoff2 = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-700.woff2`;
export const openSansBoldWoff = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-700.woff`;
export const openSansBoldTtf = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-700.ttf`;
export const openSansBoldSvg = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-700.svg`;
export const openSansBoldEot = `${s3Api}/fonts/open-sans/open-sans-v17-latin-ext_latin-700.eot`;
