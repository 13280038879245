import * as R from 'ramda';
import { fetchApi } from '../../api/apiBase';

export const partnersServiceFactory = () => {
    const getSponsors = () => fetchApi(`/sponsors?_sort=order:ASC`);

    const getSponsorsHTML = () =>
    fetchApi(`/sponsorsviews`)
            .then(res => R.head(res));

    return { getSponsors, getSponsorsHTML };
};
