import React from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { newsletterService } from '../../../services';
import { SectionTitle } from '../Titles';
import { Button } from '../Buttons';
import { Input } from './Input';
import { colors, mediaQueries, fontSize, fontFamily, lineSpacing } from '../../../styles/variables';

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    font-size: ${fontSize.mediumExtended};
    font-family: ${fontFamily.openSansSemiBold};
    line-height: ${lineSpacing.extend};

    z-index: 1;

    ${mediaQueries.sm} {
        padding-right: 2rem;
        width: 50%;
    }

    ${mediaQueries.lg} {
        width: 30%;
    }

    ${mediaQueries.lg} {
        width: 40%;
    }
`;

const SignInWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${mediaQueries.xl} {
        flex-direction: row;
    }
`;

const InputWrapper = styled(Input)`
    flex-grow: 1;
`;

const ButtonWrapper = styled(Button)`
    margin-top: 2rem;
    padding: 0.5rem 0;
    line-height: 1.6rem;
    font-size: ${fontSize.base};

    ${mediaQueries.xl} {
        margin-top: 0;
        flex-grow: 1;
    }

    ${mediaQueries.xxl} {
        flex-grow: 0.5;
    }
`;

const TextWrapper = styled.span`
    padding: 1rem 0 2rem;

    ${mediaQueries.lg} {
        width: ${props => props.width};
    }

    ${mediaQueries.xl} {
        width: auto;
    }
`;

const initialValues = { email: '' };

const getValidationSchema = t =>
    Yup.object().shape({
        email: Yup.string()
            .email(t('validation.email'))
            .required(t('validation.required')),
    });

export const NewsletterForm = ({ t, handleClick }) => {
    const submit = email => {
        newsletterService.postNewsletter(email);
        handleClick();
    };

    const validationSchema = getValidationSchema(t);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
                submit(values);
                actions.resetForm(initialValues);
            }}
        >
            {({ values }) => (
                <StyledForm>
                    <SectionTitle title={t('newsletter title')} color={colors.white} margin="0" />
                    <TextWrapper>{t('newsletter text')}</TextWrapper>
                    <SignInWrapper>
                        <Field
                            name="email"
                            type="email"
                            placeholder={t('email placeholder')}
                            component={InputWrapper}
                            values={values}
                        />
                        <ButtonWrapper type="submit">{t('newsletter button')}</ButtonWrapper>
                    </SignInWrapper>
                </StyledForm>
            )}
        </Formik>
    );
};
