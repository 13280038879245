import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Img = styled.img`
    max-width: 1.5rem;
    max-height: 1.5rem;

    :hover {
        cursor: pointer;
    }
`;

export const Icon = ({ src, alt }) => <Img src={src} alt={alt} />;

Icon.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};
