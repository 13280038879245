import * as R from 'ramda';

export const sortByLastName = (data, prop) => {
    const lastNameSort = (a, b) => {
        const getSurname = R.pipe(
            R.prop(prop),
            R.split(' '),
            R.last,
        );

        const [aSurname, bSurname] = R.map(getSurname)([a, b]);

        return aSurname.localeCompare(bSurname);
    };

    return R.sort(lastNameSort)(data);
};
