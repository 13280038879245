import { s3Api } from '../../api/apiBase';

export const footerLogos = [
    {
        name: 'FE',
        pl: `${s3Api}/icons/FE-PL.svg`,
        en: `${s3Api}/icons/FE-EN.svg`,
        width: 8,
    },
    {
        name: 'RP',
        pl: `${s3Api}/icons/RP-PL.svg`,
        en: `${s3Api}/icons/RP-EN.svg`,
        width: 12,
    },
    {
        name: 'MKiDN',
        pl: `${s3Api}/icons/MKiDN-PL.jpg`,
        en: `${s3Api}/icons/MKiDN-EN.jpg`,
        width: 7,
    },
    {
        name: 'UE',
        pl: `${s3Api}/icons/UE-PL.svg`,
        en: `${s3Api}/icons/UE-EN.svg`,
        width: 12,
    },
];
