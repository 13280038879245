import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { colors, fontSize, mediaQueries, lineSpacing, fontFamily } from '../../styles/variables';
import {
    navigationLinks,
    facebookLink,
    instagramLink,
    twitterLink,
    chopinMusicSourcesLink,
} from '../../routes/links';
import { translateDynamic } from '../../helpers';
import { Icon } from '../common/Icon';

import { facebookIcon, instaIcon, twitterIcon } from '../../static/configs/icons';
import { poster, chopinMusicSources } from '../../static/configs/images';
import { footerIframeService } from '../../services';

const Wrapper = styled.footer`
    display: flex;
    flex-direction: column;

    background-color: ${colors.codGray};
    color: ${colors.white};
`;

const Content = styled.section`
    display: flex;
    align-items: flex-start;
    padding: 0 1.5rem;

    ${mediaQueries.sm} {
        padding: 0 3rem;
    }

    ${mediaQueries.md} {
        padding: 0 5rem;
        flex-direction: row;
        justify-content: space-around;
    }

    ${mediaQueries.xl} {
        justify-content: space-between;
    }

    ${mediaQueries.xxl} {
        padding: 0 8rem;
    }
`;

const Image = styled.img`
    display: none;

    ${mediaQueries.lg} {
        display: block;
    }
`;

const SectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${mediaQueries.sm} {
        flex-direction: row;

        padding: 2rem 0;
    }

    ${mediaQueries.lg} {
        padding: 2rem 4rem;
        justify-content: space-between;
    }
`;

const InfoSection = styled.section`
    display: flex;
    padding: 1rem 4rem 1rem 0;

    ${mediaQueries.lg} {
        padding: 0;
    }
`;

const Info = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h2`
    margin: 0 0 0.5rem;
    color: ${colors.milanoRed};
    font-size: ${fontSize.mediumExtended};
    font-family: ${fontFamily.openSansBold};
    line-height: ${lineSpacing.medium};
    max-width: 5.6rem;

    ${mediaQueries.sm} {
        font-size: ${fontSize.mediumExtended};
        max-width: 8.5rem;
        margin: 0 0 1rem;
    }
`;

const Icons = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 5rem;
    padding-bottom: 0.5rem;

    ${mediaQueries.sm} {
        padding-bottom: 1rem;
    }

    * :not(:last-child) {
        margin-right: 0.7rem;
    }
`;

const Navigation = styled.nav`
    display: none;
    font-size: ${fontSize.xSmall};
    line-height: ${lineSpacing.normal};

    ${mediaQueries.sm} {
        font-size: ${fontSize.normal};
        margin-top: 1.8rem;
    }

    ${mediaQueries.xl} {
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }
`;

const StyledLink = styled.li`
    margin: 0 0 0.25rem;
    cursor: pointer;
    list-style: none;

    a {
        color: ${colors.white};
        text-decoration: none;
    }
`;

const NavigationLinks = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
`;

const GetInTouch = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${fontSize.xSmall};
    padding: 1rem 0;

    ${mediaQueries.sm} {
        font-size: ${fontSize.normal};
        margin-top: 1.8rem;
    }

    ${mediaQueries.lg} {
        padding: 0;
    }
`;

const ListElement = styled.div`
    display: flex;
    margin-bottom: 0.4rem;
    line-height: ${lineSpacing.small};
`;

const ListIcon = styled.div`
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.8rem;

    background-color: ${colors.milanoRed};
    border-radius: 50%;
`;

const ListItem = css`
    margin: 0;
    max-width: 16.1rem;
    color: ${colors.white};
    padding-bottom: 0.2rem;
`;

const ListParagraph = styled.p`
    ${ListItem}
    font-style: normal;
`;

const ListLink = styled.a`
    ${ListItem}
    text-decoration: none;
`;

const IFrameDiv = styled.div`
    height: 80px;
    width: 250px;
    overflow: hidden;
`;

const NavigationFooterContainer = ({ t, i18n }) => {
    const translate = translateDynamic(i18n);

    const [footerIframe, setIframe] = React.useState([]);

    React.useEffect(() => {
        let isMounted = true;
        footerIframeService
            .getFooterIframe()
            .then(data => {
                if (data && data[0] && data[0].iFrame && data[0].isEnabled)
                    isMounted && setIframe({ __html: data[0].iFrame });
            })
            .catch(() => {
                isMounted && setIframe();
            });

        return () => (isMounted = false);
    }, []);

    return (
        <Wrapper>
            <Content>
                <Image src={poster} />
                <SectionContainer>
                    <InfoSection>
                        <Info>
                            <Title>{t('title')}</Title>
                            <Icons>
                                <a href={facebookLink} target="_blank" rel="noreferrer noopener">
                                    <Icon src={facebookIcon} alt={t('iconAlts.facebook')} />
                                </a>
                                <a href={instagramLink} target="_blank" rel="noreferrer noopener">
                                    <Icon src={instaIcon} alt={t('iconAlts.instagram')} />
                                </a>
                                <a href={twitterLink} target="_blank" rel="noreferrer noopener">
                                    <Icon src={twitterIcon} alt={t('iconAlts.twitter')} />
                                </a>
                            </Icons>
                            <a href={chopinMusicSourcesLink} target="_blank" rel="noreferrer noopener">
                                <Image src={chopinMusicSources} />
                            </a>
                        </Info>
                    </InfoSection>
                    <Navigation>
                        <Title>{t('navigation')}</Title>
                        <NavigationLinks>
                            {navigationLinks.map(link =>
                                link.isExternal ? (
                                    <StyledLink key={link.name}>
                                        <a
                                            href={translate(link.hrefEN, link.hrefPL)}
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            {t(`navigationItems.${link.name}`)}
                                        </a>
                                    </StyledLink>
                                ) : (
                                    <StyledLink key={link.name}>
                                        <Link to={`/${i18n.language}${link.href}`}>
                                            {t(`navigationItems.${link.name}`)}
                                        </Link>
                                    </StyledLink>
                                ),
                            )}
                        </NavigationLinks>
                    </Navigation>
                    <GetInTouch>
                        <Title>{t('getInTouch')}</Title>
                        <ListElement>
                            <ListIcon />
                            <ListLink href={`mailto:${t('email')}`}>{t('email')}</ListLink>
                        </ListElement>
                        <ListElement>
                            <ListIcon />
                            <ListLink href="tel:+48224416100">(+48 22) 44 16 100</ListLink>
                        </ListElement>
                        <ListElement>
                            <ListIcon />
                            <address>
                                <ListParagraph>{t('address.institute')}</ListParagraph>
                                <ListParagraph>{t('address.street')}</ListParagraph>
                                <ListParagraph>{t('address.postcode')}</ListParagraph>
                            </address>
                        </ListElement>
                        {footerIframe.__html ? (
                            <IFrameDiv dangerouslySetInnerHTML={footerIframe}></IFrameDiv>
                        ) : (
                            ''
                        )}
                    </GetInTouch>
                </SectionContainer>
            </Content>
        </Wrapper>
    );
};

export const NavigationFooter = withTranslation('layout')(NavigationFooterContainer);
