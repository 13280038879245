import React from 'react';
import styled, { css } from 'styled-components';

import { withTranslation } from 'react-i18next';
import { colors, fontSize, mediaQueries, fontFamily } from '../../styles/variables';

const getPaddingValueDependingOnScrollPositionAndBarVisibility = ({
    isScrolled,
    isStreamBarVisible,
}) => {
    const getPaddingValueDependingOnBarVisibility = isStreamBarVisible ? '4rem 7rem 0;' : '0 7rem 0;';

    return isScrolled ? '0 7rem 0;' : getPaddingValueDependingOnBarVisibility;
};

const ScrolledComponent = css`
    height: 0;
    opacity: 0;
    padding-top: 0;
`;

const Wrapper = styled.div`
    display: none;

    justify-content: flex-end;
    align-items: center;
    color: ${colors.dustyGray};

    padding: 0 5rem;
    height: 1.5rem;
    font-size: ${fontSize.xSmall};

    ${mediaQueries.lg} {
        display: flex;
        padding-top: ${props => (props.isStreamBarVisible ? '4rem;' : '0;')};
        ${props => props.isScrolled && ScrolledComponent};
        ${props =>
            props.isScrolled
                ? 'transition: opacity 0.2s, height 0.4s'
                : 'transition: opacity 0.2s 0.2s, height 0.2s'};
    }

    ${mediaQueries.xl} {
        padding: ${props => getPaddingValueDependingOnScrollPositionAndBarVisibility(props)};
        font-size: ${fontSize.small};
    }
`;

const StyledList = styled.ul`
    padding: 1rem 0 0;
    margin: 0;
`;

const StyledListItem = styled.li`
    display: inline;
    font-family: ${props => (props.isActive ? fontFamily.openSansSemiBold : fontFamily.openSans)};

    :hover {
        cursor: pointer;
    }

    &:after {
        content: '';
        padding: 0 0.5rem;
    }

    &:last-child::after {
        padding: 0;
    }
`;

export const languages = [
    { code: 'en', label: 'English' },
    { code: 'pl', label: 'Polski' },
];

const LanguageAndInfoContainer = ({ i18n, isScrolled, isStreamBarVisible }) => (
    <Wrapper isScrolled={isScrolled} isStreamBarVisible={isStreamBarVisible}>
        <StyledList>
            {languages.map(language => (
                <StyledListItem
                    key={language.code}
                    onClick={() => i18n.changeLanguage(language.code)}
                    isActive={i18n.language === language.code}
                >
                    {language.label}
                </StyledListItem>
            ))}
        </StyledList>
    </Wrapper>
);

export const LanguageAndInfo = withTranslation('layout')(LanguageAndInfoContainer);
