import { s3Api } from '../../api/apiBase';

export const appIcon = `${s3Api}/icons/appstore.svg`;
export const appleIcon = `${s3Api}/icons/appleIcon.svg`;
export const arrowBackIcon = `${s3Api}/icons/v2_arrow-back.svg`;
export const arrowLeft = `${s3Api}/icons/v2_arrowLeft.svg`;
export const arrowRight = `${s3Api}/icons/v2_arrowRight.svg`;
export const arrowLeftWhite = `${s3Api}/icons/v2_arrowLeft_white.svg`;
export const arrowRightWhite = `${s3Api}/icons/v2_arrowRight_white.svg`;
export const arrowDropdown = `${s3Api}/icons/v2_arrow_dropdown.svg`;
export const arrowDropdownBlack = `${s3Api}/icons/v2_arrow_dropdown_black.svg`;
export const articlesIcon = `${s3Api}/icons/v2_articles.svg`;
export const audioImage = `${s3Api}/images/composition.png`;
export const awardBorder = `${s3Api}/icons/award.svg`;
export const backIcon = `${s3Api}/icons/v2_arrow-back2.svg`;
export const calendarIcon = `${s3Api}/icons/v2_calendar.svg`;
export const clockIcon = `${s3Api}/icons/v2_clock.svg`;
export const clockIconBlack = `${s3Api}/icons/clockBlack.svg`;
export const closeIcon = `${s3Api}/icons/close-icon.svg`;
export const closeIconBlack = `${s3Api}/icons/close_black.svg`;
export const closeWhiteIcon = `${s3Api}/icons/v2_close_white.svg`;
export const commentDefault = `${s3Api}/icons/v2_comment_default.svg`;
export const compositionIcon = `${s3Api}/icons/v2_composition.svg`;
export const downloadIcon = `${s3Api}/icons/v2_download.svg`;
export const emailIcon = `${s3Api}/icons/v2_mail.svg`;
export const facebookIcon = `${s3Api}/icons/v2_Facebook.svg`;
export const facebookRegister = `${s3Api}/icons/v2_register-facebook.svg`;
export const filterIcon = `${s3Api}/icons/filter-black.svg`;
export const flagIconSmall = `${s3Api}/icons/NIFC-small.svg`;
export const flagIconTitle = `${s3Api}/icons/NIFC-tittle.svg`;
export const fullStarIcon = `${s3Api}/icons/v2_star-full.svg`;
export const googleIcon = `${s3Api}/icons/googleplay.svg`;
export const googleRegister = `${s3Api}/icons/register-google.svg`;
export const headphoneIcon = `${s3Api}/icons/v2_headphones.svg`;
export const heart = `${s3Api}/icons/heart-line.svg`;
export const heartFull = `${s3Api}/icons/heart-red.svg`;
export const heartIcon = `${s3Api}/icons/heart-white.svg`;
export const heartIconWhite = `${s3Api}/icons/heart-white-line3.svg`;
export const heartIconWhiteFull = `${s3Api}/icons/heart-white-full.svg`;
export const instaIcon = `${s3Api}/icons/v2_Insta.svg`;
export const locationIcon = `${s3Api}/icons/v2_location.svg`;
export const locationIconBlack = `${s3Api}/icons/locationBlack.svg`;
export const pdfIcon = `${s3Api}/icons/pdf.svg`;
export const photoIcon = `${s3Api}/icons/v2_photo.svg`;
export const phoneIcon = `${s3Api}/icons/v2_phone.svg`;
export const playIcon = `${s3Api}/icons/v2_play.svg`;
export const pauseIcon = `${s3Api}/icons/v2_pause.svg`;
export const placeholderIcon = `${s3Api}/icons/placeholder.svg`;
export const rulesPianoIcon = `${s3Api}/icons/piano2.svg`;
export const rulesNoteIcon = `${s3Api}/icons/rules_note.svg`;
export const rulesFlagIcon = `${s3Api}/icons/flag2.svg`;
export const rulesUsersIcon = `${s3Api}/icons/users.svg`;
export const rulesCrownIcon = `${s3Api}/icons/crown.svg`;
export const rulesPlusIcon = `${s3Api}/icons/plus-circle.svg`;
export const rulesPdfIcon = `${s3Api}/icons/pdf.svg`;
export const searchIcon = `${s3Api}/icons/loop_black.svg`;
export const shareFacebook = `${s3Api}/icons/share-facebook.svg`;
export const shareTwitter = `${s3Api}/icons/share-twitter.svg`;
export const shareMessenger = `${s3Api}/icons/share-messenger.svg`;
export const shareFacebookWhite = `${s3Api}/icons/share-facebook-white.svg`;
export const shareTwitterWhite = `${s3Api}/icons/share-twitter-white.svg`;
export const shareMessengerWhite = `${s3Api}/icons/share-messenger-white.svg`;
export const starIcon = `${s3Api}/icons/v2_star.svg`;
export const streamIcon = `${s3Api}/icons/streamIcon.svg`;
export const twitterIcon = `${s3Api}/icons/v2_Twitter.svg`;
export const videoIcon = `${s3Api}/icons/v2_video.svg`;
export const youtubeIcon = `${s3Api}/icons/v2_youtube.svg`;
