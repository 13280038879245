import { fetchApi } from "../../api/apiBase";

export const awardsServiceFactory = () => {
    const getMainAwards = () =>
        fetchApi(`/awardscompetitions?_sort=order:ASC`);

    const getSpecialAwards = () =>
        fetchApi(`/awardsspecials?_sort=order:ASC`);

    const getOtherAwards = () =>
        fetchApi(`/awardsothers?_sort=order:ASC`);

    return { getMainAwards, getSpecialAwards, getOtherAwards };
};
