import cookie from 'js-cookie';

export const login = ({ token }) => cookie.set('token', token);

export const getToken = () => {
    const token = cookie.get('token');
    return token;
};

export const logout = () => {
    cookie.remove('token');
};
