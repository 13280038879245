import React from 'react';
import styled, { keyframes } from 'styled-components';

import { colors } from '../../styles/variables';

const loading = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpin = styled.div`
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);

    width: 1.5rem;
    height: 1.5rem;
    border: 0.3rem solid rgb(0, 0, 0, 0.2);
    border-left: 0.3rem solid ${colors.milanoRed};
    border-radius: 50%;

    animation: ${loading} 1.1s infinite linear;
`;

const SpinnerWrapper = styled.div`
    position: relative;

    width: 100%;
    height: calc(100vh - 13rem);
`;

const SpinnerLayoutWrapper = styled.div`
    position: relative;

    width: 100%;
    height: 100vh;
`;

export const SpinnerComponent = () => (
    <SpinnerWrapper>
        <StyledSpin />
    </SpinnerWrapper>
);

export const SpinnerLayoutComponent = () => (
    <SpinnerLayoutWrapper>
        <StyledSpin />
    </SpinnerLayoutWrapper>
);
