import React from 'react';
import styled from 'styled-components';

import { mediaQueries, colors } from '../../styles/variables';

import { LanguageAndInfo } from './LanguageAndInfo';
import { MainSection } from './MainSection';
import { MobileHeader } from './MobileHeader';
import { Navigation } from './Navigation';
import { PageWrapper } from '../common/PageWrapper';
import StreamInfo from './StreamInfo';

const Wrapper = styled.header`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 3;
    background-color: ${colors.codGray};

    ${mediaQueries.lg} {
        position: fixed;
    }
`;

export const Header = ({ isScrolled, handleStreamBarVisibility, isStreamBarVisible }) => (
    <Wrapper>
        <StreamInfo
            isScrolled={isScrolled}
            handleStreamBarVisibility={handleStreamBarVisibility}
            isStreamBarVisible={isStreamBarVisible}
        />
        <PageWrapper>
            <MobileHeader />
            <LanguageAndInfo isScrolled={isScrolled} isStreamBarVisible={isStreamBarVisible} />
            <MainSection isScrolled={isScrolled} />
            <Navigation isScrolled={isScrolled} />
        </PageWrapper>
    </Wrapper>
);
