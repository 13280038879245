export const favoritesServiceFactory = (apiBase, fetch) => {
    const getFavorites = token =>
        fetch(`${apiBase}/user/favoriteParticipants`, {
            credentials: 'include',
            headers: { Authorization: `Bearer ${token}` },
        }).then(res => res.json());

    const addToFavorites = (token, id) =>
        fetch(`${apiBase}/user/favoriteParticipants`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ participantId: id }),
        });

    const removeFromFavorites = (token, id) =>
        fetch(`${apiBase}/user/favoriteParticipants/${id}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${token}` },
        });

    const getSessions = token =>
        fetch(`${apiBase}/user/calendariumSessions`, {
            headers: { Authorization: `Bearer ${token}` },
        }).then(res => res.json());

    const saveSession = (token, id) =>
        fetch(`${apiBase}/user/calendariumSessions`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId: id }),
        });

    const removeSession = (token, id) =>
        fetch(`${apiBase}/user/calendariumSessions/${id}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${token}` },
        });

    return {
        getFavorites,
        addToFavorites,
        removeFromFavorites,
        getSessions,
        saveSession,
        removeSession,
    };
};
