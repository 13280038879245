import styled from 'styled-components';

import { mediaQueries } from '../../styles/variables';

export const PageWrapper = styled.div`
    ${mediaQueries.xxxl} {
        max-width: 1600px;
        margin: 0 auto;
    }
`;
