import { fetchApi } from "../../api/apiBase";

export const footerIframeServiceFactory = () => {
    const getFooterIframe = () =>
        fetchApi(`/footeriframes`);

    return {
        getFooterIframe,
    };
};
