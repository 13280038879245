import styled from 'styled-components';

import { colors, fontSize } from '../../styles/variables';

export const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${colors.white};
    background-color: ${colors.milanoRed};
    outline: transparent 0;
    border: none;
    font-size: ${fontSize.base};

    padding: ${props => (props.padding ? props.padding : '0 1rem;')};

    cursor: pointer;

    :focus,
    :active {
        outline: transparent 0;
    }
`;

export const BlackButton = styled(Button)`
    background-color: ${colors.black};
    padding: ${props => (props.padding ? props.padding : '1rem 3rem;')};
`;
