const mediaQ = size => `@media only screen and (min-width: ${size}px)`;

export const colors = {
    black: '#000000',
    white: '#FFFFFF',
    mineShaft: '#202020',
    putty: '#E7C78B',
    sundance: '#e5d5a3',
    twine: '#A3864F',
    quillGray: '#D8D8D6',
    darkGrey: '#353535',
    emperor: '#363636',
    aluminum: '#A9ACB6',
    background: '#1E1E1E',
    brown: '#AA6E10',
    darkTwine: '#78633A',
    milanoRed: '#D01901',
    doveGray: '#707070',
    codGray: '#141414',
    silverChalice: '#ACACAC',
    dustyGray: '#999999',
    grey: '#00000029',
    mine: '#393939',
    navyBlue: '#15017C',
    yellow: '#FDB201',
    green: '#667A3B',
    alabaster: '#F7F7F7',
    mercury: '#e5e5e5',
    ultraLightGray: '#F4F4F4',
    sanMarino: '#4267b2',
    wildSand: '#F4F4F4',
    lightGray: '#404040',
    blueGray: '#E4E6EA',
    athensGray: '#F5F6F8',
};

export const mediaQueries = {
    xs: mediaQ(320),
    sm: mediaQ(576),
    md: mediaQ(850),
    lg: mediaQ(992),
    xl: mediaQ(1200),
    xxl: mediaQ(1440),
    xxxl: mediaQ(1600),
    xxxxl: mediaQ(1800),
};

export const fontWeight = {
    light: 300,
    medium: 400,
    semiBold: 600,
    heavy: 700,
    extraBold: 800,
};

export const fontSize = {
    xxSmall: '0.7rem',
    xSmall: '0.8rem',
    small: '0.9rem',
    base: '16px',
    normal: '1rem',
    normalExtended: '1.1rem',
    medium: '1.2rem',
    mediumExtended: '1.3rem',
    large: '1.5rem',
    largeExtended: '1.8rem',
    extraLarge: '2rem',
    extraLargeExtended: '2.5rem',
    biggest: '3rem',
    biggestExtended: '4rem',
    offTheScale: '5rem',
};

export const fontFamily = {
    openSans: 'OpenSans Regular',
    openSansSemiBold: 'OpenSans SemiBold',
    openSansBold: 'OpenSans Bold',
    alegreya: 'Alegreya Regular',
    alegreyaBold: 'Alegreya Bold',
    alegreyaExtraBold: 'Alegreya ExtraBold',
};

export const lineSpacing = {
    small: '1.4rem',
    normal: '1.7rem',
    medium: '1.9rem',
    extend: '2rem',
    extraExtend: '2.5rem',
    biggest: '5rem',
};

export const filterColor = {
    gold: 'invert(54%) sepia(37%) saturate(483%) hue-rotate(1deg) brightness(93%) contrast(91%)',
    silver: 'invert(89%) sepia(57%) saturate(0%) hue-rotate(203deg) brightness(87%) contrast(96%)',
    brown: 'invert(36%) sepia(97%) saturate(639%) hue-rotate(5deg) brightness(98%) contrast(87%)',
    white: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(108deg) brightness(106%) contrast(101%)',
};
