import React from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import * as R from 'ramda';

import i18n from '../i18n';
import { getBrowserLanguage } from '../helpers';
import { routesList } from './routesList';
import { Layout } from '../layout';
import { SpinnerLayoutComponent } from '../components/common/Spinner';
import Error from '../pages/error';

export const Routes = ({ match, drawApiBaseLink }) => {
    const location = useLocation();
    const history = useHistory();
    const languages = ['en', 'pl'];
    const [pathName, setPathName] = React.useState('');

    React.useEffect(() => {
        const url = location.pathname.split('/');

        if (R.includes(url[1], languages)) {
            return;
        }

        const languageToSet = getBrowserLanguage();

        history.replace(`/${languageToSet}/${url.slice(1).join('/')}`);
        i18n.changeLanguage(languageToSet);
    }, [location]); // eslint-disable-line

    React.useEffect(() => {
        return history.listen(location => {
            setPathName(location.pathname);
        });
    }, [history]);

    React.useEffect(() => {
        drawApiBaseLink();
    }, [pathName]);

    return (
        <Layout>
            <React.Suspense fallback={<SpinnerLayoutComponent />}>
                <Switch>
                    {routesList.map(route => (
                        <Route
                            exact={route.exact}
                            path={match.url + route.path}
                            component={route.component}
                            key={route.component}
                        />
                    ))}
                    <Route component={Error} />
                </Switch>
            </React.Suspense>
        </Layout>
    );
};

export const routes = {
    accreditation: '/accreditation',
    accreditationSuccess: '/accreditation/success',
    article: '/article',
    calendar: '/calendar',
    compositions: '/compositions',
    competition: '/competition',
    competitors: '/competitors',
    deactivateAccount: '/profile/success',
    favorite: '/profile/favorite-competitors',
    home: '/',
    juries: '/jury/jury-competition',
    jury: '/jury',
    login: '/login',
    multimediaCompetition: '/multimedia/competition-transmission',
    multimediaOther: '/multimedia/other',
    multimediaParticipant: '/participant',
    news: '/news',
    profile: '/profile',
    registerActivate: '/login/activate',
    registerSuccess: '/login/success',
    resetPassword: '/reset-password',
    resetPasswordEmail: '/reset-password-email',
    resetPasswordSuccess: '/reset-password-success',
    resendEmail: '/resend-email',
    resendEmailSuccess: '/resend-mail-success',
};
