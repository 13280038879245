export const getCurrentDate = () => {
    const getDate = new window.Date();

    const year = getDate.getUTCFullYear();
    const month =
        (getDate.getUTCMonth() + 1).toString().length === 1
            ? `0${getDate.getUTCMonth() + 1}`
            : getDate.getUTCMonth() + 1;

    const day =
        (getDate.getUTCDate() + 1).toString().length === 1
            ? `0${getDate.getUTCDate()}`
            : getDate.getUTCDate();

    return `${year}-${month}-${day}`;
};
