import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { colors, mediaQueries, fontFamily, fontSize } from '../../../styles/variables';
import { Button } from '../Buttons';

import { closeIcon } from '../../../static/configs/icons';

export const PopUpContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    width: 80%;
    height: 50%;
    max-width: 30rem;

    background-color: ${colors.white};
    color: ${colors.black};

    transform: translate(-50%, -50%);

    ${mediaQueries.sm} {
        width: 50%;
        height: 43%;
    }

    ${mediaQueries.md} {
        width: 50%;
        height: 45%;
    }

    ${mediaQueries.lg} {
        height: 50%;
    }

    ${mediaQueries.xl} {
        height: 70%;
    }

    ${mediaQueries.xxl} {
        height: 75%;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 1rem 1.5rem 0;

    ${mediaQueries.sm} {
        padding: 1rem 3rem 0;
    }
`;

const CloseIcon = styled.img`
    cursor: pointer;
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: 1rem;
    right: 1rem;
`;

const Paragraph = styled.p`
    font-size: ${fontSize.medium};
    text-align: left;
    margin: 0 0 1.5rem;

    &:nth-child(2n) {
        font-family: ${fontFamily.openSansSemiBold};
    }
`;

const ButtonWrapper = styled(Button)`
    background-color: ${colors.black};

    width: 10rem;
    height: 2.6rem;
    line-height: 2.6rem;

    cursor: pointer;

    color: white;
`;

const NewsletterPopUpContainer = ({ handleClick, t }) => (
    <PopUpContainer>
        <TextContainer>
            <CloseIcon onClick={handleClick} src={closeIcon} alt={t('newsletter icon')} />
            <Paragraph>{t('newsletter popup-1')}</Paragraph>
            <Paragraph>{t('newsletter popup-2')}</Paragraph>
        </TextContainer>
        <ButtonWrapper onClick={handleClick}>{t('newsletter close')}</ButtonWrapper>
    </PopUpContainer>
);

export const NewsletterPopUp = withTranslation('home')(NewsletterPopUpContainer);
