import React from 'react';
import styled from 'styled-components';

import { NavigationFooter } from './NavigationFooter';
import { NewsletterFooter } from './NewsletterFooter';
import { PageWrapper } from '../common/PageWrapper';
import { colors } from '../../styles/variables';

const Wrapper = styled.footer`
    background-color: ${colors.codGray};
`;

export const TopFooter = () => (
    <Wrapper>
        <PageWrapper>
            <NewsletterFooter />
            <NavigationFooter />
        </PageWrapper>
    </Wrapper>
);
