import { fetchApi } from '../../api/apiBase';

export const compositionServiceFactory = () => {
    const getCompositionsCount = () => fetchApi(`/compositions/count`).then(res => parseInt(res));

    const getCompositions = () => fetchApi(`/compositions?_limit=1000`);

    const getPaginatedCompositions = (page = 0, limit = 10) => {
        const start = parseInt(page) * parseInt(limit);
        fetchApi(`/compositions?_start=${start}&_limit=${parseInt(limit)}`);
    };

    const getComposition = id => fetchApi(`/compositions/${id}`);

    return {
        getCompositionsCount,
        getCompositions,
        getPaginatedCompositions,
        getComposition,
    };
};
