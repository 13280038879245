import { fetchApi } from "../../api/apiBase";

export const calendariumServiceFactory = () => {
    const getMasterGroups = () =>
        fetchApi(`/calendariummastergroups?_sort=order:ASC`);

    const getGroups = () =>
        fetchApi(`/calendariumgroups?_sort=title_en:ASC`);

    const getEvents = () =>
        fetchApi(`/calendariumevents?_sort=start_date:asc&_limit=-1`);

    const getPlaces = () => fetchApi(`/calendariumplaces?_limit=-1`);

    const getEventsByGroup = groupId =>
        fetchApi(
            `/calendariumevents?_sort=start_date:asc&_limit=-1&calendariumgroup=${groupId}`,
        );

    const getEventById = id => fetchApi(`/calendariumevents/${id}`);

    const getSessions = () => fetchApi(`/calendariumsessions`);

    return {
        getMasterGroups,
        getGroups,
        getEvents,
        getPlaces,
        getEventsByGroup,
        getEventById,
        getSessions,
    };
};
