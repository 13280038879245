import React from 'react';
import styled from 'styled-components';

import { colors, mediaQueries, fontFamily } from '../../styles/variables';

import { streamIcon } from '../../static/configs/icons';

const Board = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: ${colors.milanoRed};
    border-radius: 4px;
    padding: 0.5rem;
    margin-right: 2rem;

    ${mediaQueries.lg} {
        padding: 0.5rem 1rem;
        min-width: 11rem;
    }
`;

const Icon = styled.img`
    height: 1.5rem;

    ${mediaQueries.lg} {
        margin-right: 1rem;
    }
`;

const Title = styled.h4`
    display: none;
    margin: 0;

    color: ${colors.white};
    font-family: ${fontFamily.openSansSemiBold};
    text-transform: uppercase;

    ${mediaQueries.lg} {
        display: block;
    }
`;

export const StreamIcon = () => (
    <Board>
        <Icon src={streamIcon} />
        <Title>stream live</Title>
    </Board>
);
