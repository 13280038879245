import { fetchApi } from "../../api/apiBase";

export const newsletterServiceFactory = () => {
    const postNewsletter = ({ email }) =>
        fetchApi(`/newsletters`, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({ email }),
        });

    return {
        postNewsletter,
    };
};
