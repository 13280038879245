import { fetchApi } from '../../api/apiBase';

export const juryServiceFactory = () => {
    const getJuries = () => fetchApi(`/juries/`);

    const getJury = ({ id }) => fetchApi(`/juries/${id}`);

    const getJuriesByType = ({ type }) =>
        fetchApi(`/juries?types.name=${type}`);

    return {
        getJuries,
        getJury,
        getJuriesByType,
    };
};
