import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { navigationLinks } from '../../routes/links';
import { routes } from '../../routes';
import { translateDynamic, logout } from '../../helpers';
import { useAuth } from '../../helpers/auth/Context';
import { colors, fontSize, mediaQueries, fontFamily } from '../../styles/variables';
import { languages } from './LanguageAndInfo';
import { Button } from '../common/Buttons';

import { competitionTitle } from '../../static/configs/images';

const Wrapper = styled.header`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 2rem;
    padding: 1.5rem;

    ${mediaQueries.sm} {
        padding: 1.5rem 3rem;
    }

    ${mediaQueries.lg} {
        display: none;
    }
`;

const MenuContainer = styled.div`
    position: absolute;
    width: 1.7rem;
    height: 1.5rem;
    left: 1rem;
    overflow: hidden;

    ${mediaQueries.sm} {
        width: 2rem;
        left: 3rem;
    }
`;

const MenuIcon = styled.div`
    position: relative;

    :after,
    :before,
    div {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${colors.white};
        border-radius: 0.3rem;
        content: '';

        height: 2px;
        width: 100%;
        transition: ${props =>
            props.isOpen
                ? 'top 0.2s ease-in-out, transform 0.2s ease-in-out 0.2s'
                : 'transform 0.2s ease-in-out, top 0.2s ease-in-out 0.2s'};

        ${mediaQueries.sm} {
            height: 3px;
        }
    }

    div {
        top: ${props => (props.isOpen ? 'calc(0.75rem - 1.5px)' : '0')};
        transform: ${props => props.isOpen && 'rotate(-45deg)'};
    }

    :after {
        top: ${props => (props.isOpen ? 'calc(0.75rem - 1.5px)' : 'calc(1.5rem - 3px)')};
        transform: ${props => props.isOpen && 'rotate(45deg)'};
    }

    :before {
        top: calc(0.75rem - 1.5px);
        opacity: ${props => props.isOpen && '0'};
    }
`;

const Menu = css`
    display: ${props => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    z-index: 2;
    position: absolute;
    max-height: calc(100vh - 7.5rem);
    overflow-y: scroll;
    top: 5rem;
    color: ${colors.white};
    background-color: ${colors.black};
    text-transform: none;
`;

const NavigationMenu = styled.ul`
    ${Menu};
    width: 100%;
    left: 0;
    padding: 0;
    margin: 0;
`;

const StyledLink = styled.li`
    padding: 0.8rem 1.5rem;
    border-bottom: 1px solid;
    text-decoration: none;
    transition: 0.2s background-color ease-out;
    font-family: ${fontFamily.openSansSemiBold};
    list-style: none;
    color: ${colors.white};

    a {
        color: ${colors.white};
    }

    ${mediaQueries.sm} {
        padding: 0.8rem 3rem;
    }

    :hover {
        cursor: pointer;
        background-color: ${colors.emperor};
    }
`;

const NavigationDropdown = styled(StyledLink)`
    background-color: ${colors.doveGray};
    color: ${colors.white};
`;

const Title = styled.img`
    max-height: 2.1rem;

    ${mediaQueries.sm} {
        max-height: 3rem;
    }

    :hover {
        cursor: pointer;
    }
`;

const LanguageSection = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    right: 1rem;
    color: ${colors.white};
    cursor: pointer;
    font-size: ${fontSize.normalExtended};

    ${mediaQueries.sm} {
        right: 3rem;
        font-size: ${fontSize.medium};
    }
`;

const ArrowIcon = styled.span`
    padding: 0.3rem;
    font-size: ${fontSize.xxSmall};

    ${mediaQueries.sm} {
        font-size: ${fontSize.small};
    }
`;

const LanguageMenu = styled.div`
    ${Menu};
    top: 4rem;
    right: 0;
    border: 1px solid ${colors.white};

    ${mediaQueries.sm} {
        right: 1.5rem;
    }
`;

const Language = styled.p`
    margin: 0;
    padding: 1rem;
    cursor: pointer;
    transition: 0.2s background-color ease-out;

    :hover {
        background-color: ${colors.emperor};
    }
`;

const Line = styled.div`
    background-color: ${colors.milanoRed};
    width: 4rem;
    height: 3px;
`;

const ButtonWrapper = styled(Button)`
    padding: 0.5rem;
    margin: 0.8rem 1.5rem;
    max-width: 10rem;

    ${mediaQueries.sm} {
        margin: 0.8rem 3rem;
    }
`;

const MobileHeaderContainer = ({ t, i18n }) => {
    const [isNavigationMenuOpen, setIsNavigationMenuOpen] = useState(false);
    const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

    const [dropdown, setDropdown] = React.useState('');

    const handleDropdown = name => (dropdown ? setDropdown('') : setDropdown(name));

    const languageClickHandler = lang => {
        i18n.changeLanguage(lang);
        setIsLanguageMenuOpen(false);
    };

    const { userData, setIsAuthenticated } = useAuth();

    const logoutUser = () => {
        logout();
        setIsAuthenticated(false);
    };

    const translate = translateDynamic(i18n);

    return (
        <Wrapper>
            <MenuContainer onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}>
                <MenuIcon isOpen={isNavigationMenuOpen}>
                    <div></div>
                </MenuIcon>
            </MenuContainer>
            <NavigationMenu isOpen={isNavigationMenuOpen}>
                {userData && !userData.error && (
                    <StyledLink>
                        <Link
                            to={`/${i18n.language}${routes.profile}`}
                            onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}
                        >
                            {userData.name}
                            <Line />
                        </Link>
                    </StyledLink>
                )}
                {navigationLinks.map(link =>
                    link.isExternal ? (
                        <a
                            href={translate(link.hrefEN, link.hrefPL)}
                            target="_blank"
                            rel="noreferrer noopener"
                            key={link.name}
                        >
                            <StyledLink>{t(`navigationItems.${link.name}`)}</StyledLink>
                        </a>
                    ) : link.isDropdown ? (
                        <React.Fragment key={link.name}>
                            <StyledLink onClick={() => handleDropdown(link.name)}>
                                {t(`navigationItems.${link.name}`)}
                            </StyledLink>
                            {dropdown &&
                                dropdown === link.name &&
                                link.dropdown.map(item => (
                                    <Link
                                        key={item.name}
                                        to={`/${i18n.language}${item.href}`}
                                        onClick={() => {
                                            setIsNavigationMenuOpen(!isNavigationMenuOpen);
                                            setDropdown('');
                                        }}
                                    >
                                        <NavigationDropdown>
                                            {t(`navigationItems.dropdown.${item.name}`)}
                                        </NavigationDropdown>
                                    </Link>
                                ))}
                        </React.Fragment>
                    ) : (
                        <Link key={link.name} to={`/${i18n.language}${link.href}`}>
                            <StyledLink
                                to={`/${i18n.language}${link.href}`}
                                onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}
                            >
                                {t(`navigationItems.${link.name}`)}
                            </StyledLink>
                        </Link>
                    ),
                )}
                {!userData || userData.error ? (
                    <Link
                        to={`/${i18n.language}${routes.login}`}
                        onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}
                    >
                        <ButtonWrapper>
                            {t('login')}/{t('register')}
                        </ButtonWrapper>
                    </Link>
                ) : (
                    <ButtonWrapper
                        onClick={() => {
                            logoutUser();
                            setIsNavigationMenuOpen(!isNavigationMenuOpen);
                        }}
                    >
                        {t('logout')}
                    </ButtonWrapper>
                )}
            </NavigationMenu>

            <Link
                to={`/${i18n.language}`}
                onClick={() => setIsNavigationMenuOpen(!isNavigationMenuOpen)}
            >
                <Title src={competitionTitle} />
            </Link>

            <LanguageSection onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)}>
                <ArrowIcon>{isLanguageMenuOpen ? '▲' : '▼'}</ArrowIcon>
                {i18n.language.toUpperCase()}
            </LanguageSection>
            <LanguageMenu isOpen={isLanguageMenuOpen}>
                {languages.map(language => (
                    <Language key={language.code} onClick={() => languageClickHandler(language.code)}>
                        {language.label}
                    </Language>
                ))}
            </LanguageMenu>
        </Wrapper>
    );
};

export const MobileHeader = withTranslation('layout')(MobileHeaderContainer);
