import React from 'react';
import styled from 'styled-components';

import { colors, fontWeight, fontFamily, fontSize, mediaQueries } from '../../styles/variables';

const SectionTitleItem = styled.h3`
    color: ${props => (props.color ? props.color : colors.black)};
    font-family: ${fontFamily.openSansSemiBold};
    font-size: ${fontSize.medium};
    letter-spacing: 4px;
    text-transform: uppercase;
    border-bottom: 2px ${colors.milanoRed} solid;
    padding-bottom: 0.3rem;
    margin: ${props => props.margin || '3rem auto'};
    text-align: center;

    ${mediaQueries.sm} {
        font-size: ${fontSize.mediumExtended};
        letter-spacing: 5px;
    }
`;

const SectionTitleContainer = styled.div`
    display: flex;
    width: 100%;
`;

export const SectionTitle = ({ title, color, margin }) => (
    <SectionTitleContainer>
        <SectionTitleItem color={color} margin={margin}>
            {title}
        </SectionTitleItem>
    </SectionTitleContainer>
);

export const ArticleTitle = styled.h2`
    margin: 0 0 0.5rem;
    font-family: ${fontFamily.alegreya};
    color: ${colors.black};
    font-size: ${fontSize.extraLarge};
    font-weight: ${fontWeight.medium};

    max-width: 60rem;

    ${mediaQueries.md} {
        font-size: ${fontSize.biggest};
    }
`;

export const ParagraphTitle = styled.h3`
    color: ${colors.milanoRed};
    font-family: ${fontFamily.alegreya};
    font-size: ${fontSize.extraLarge};

    max-width: 60rem;
    margin: 0.5rem 0;
`;

const dotColors = [colors.milanoRed, colors.navyBlue, colors.yellow, colors.green];

const DotContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 5rem;
    margin: ${props => props.margin || '0.5rem 0 2.5rem'};
`;

const Dot = styled.div`
    background-color: ${props => props.color};
    width: 0.55rem;
    height: 0.55rem;
    border-radius: 50%;
`;

export const ColorfulDots = ({ margin }) => (
    <DotContainer margin={margin}>
        {dotColors.map(dot => (
            <Dot key={dot} color={dot} />
        ))}
    </DotContainer>
);
