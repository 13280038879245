import React from 'react';

import { LogosFooter } from './LogosFooter';
import { MarkFooter } from './MarkFooter';
import { PageWrapper } from '../common/PageWrapper';

export const BottomFooter = () => (
    <PageWrapper>
        <LogosFooter />
        <MarkFooter />
    </PageWrapper>
);
