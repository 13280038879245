import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';

import { useGetPromise } from '../../hooks/usePromise';
import { streamInfoService, multimediaCompetitionService } from '../../services';
import { translateDynamic, replaceSpacesWithDashes } from '../../helpers';
import { routes } from '../../routes';
import { colors, mediaQueries, fontSize } from '../../styles/variables';

import { PageWrapper } from '../common/PageWrapper';
import { StreamIcon } from './StreamIcon';

const StreamSection = styled.section`
    background-color: ${colors.white};
    width: 100%;

    ${mediaQueries.lg} {
        position: absolute;
        top: 0;
        left: 0;

        z-index: 2;
        height: 4rem;

        transform: ${props => (props.isScrolled ? 'translateY(-130%);' : 'translateY(0%);')};
        transition: ${props => (props.isScrolled ? 'transform 0.6s;' : 'transform 0.6s 0.2s;')};
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;

    padding: 0.5rem 1.5rem;

    ${mediaQueries.sm} {
        padding: 0.5rem 3rem;
    }

    ${mediaQueries.lg} {
        padding: 0.5rem 5rem;
    }

    ${mediaQueries.xl} {
        padding: 0.5rem 7rem;
    }
`;

const StyledLink = styled(Link)`
    color: ${colors.black};
    font-size: ${fontSize.medium};
`;

const StreamInfo = ({ i18n, isScrolled, isStreamBarVisible, handleStreamBarVisibility }) => {
    const translate = translateDynamic(i18n);

    const [streamInfo, isLoaded] = useGetPromise(streamInfoService.getStreamInfo, []);
    const streamInfoItem = R.head(streamInfo);

    const getCompetitionGalleryById = React.useCallback(
        () =>
            streamInfoItem &&
            multimediaCompetitionService.getCompetitionGalleryById({ id: streamInfoItem.gallery_id }),
        [streamInfoItem],
    );
    const [competitionGallery] = useGetPromise(getCompetitionGalleryById, []);
    const competitionGalleryItem = R.head(competitionGallery);

    const competitionGalleryTitle = translate(
        competitionGalleryItem?.title_en,
        competitionGalleryItem?.title_pl,
    );

    React.useEffect(() => {
        isLoaded && handleStreamBarVisibility(streamInfoItem ? streamInfoItem.is_published : false);
    }, [isLoaded, streamInfoItem, handleStreamBarVisibility]);

    return (
        <>
            {isStreamBarVisible && (
                <StreamSection isScrolled={isScrolled}>
                    <PageWrapper>
                        <StyledLink
                            to={{
                                pathname: `/${i18n.language}${routes.multimediaCompetition}/${
                                    streamInfoItem?.gallery_id
                                }/${competitionGalleryTitle &&
                                    replaceSpacesWithDashes(competitionGalleryTitle)} `,
                                state: {
                                    category: 'video',
                                    id: streamInfoItem.video_id,
                                    categoryOfGallery: 'multimediagallery',
                                },
                            }}
                        >
                            <Wrapper>
                                <StreamIcon />
                                {translate(streamInfoItem.title_en, streamInfoItem.title_pl)}
                            </Wrapper>
                        </StyledLink>
                    </PageWrapper>
                </StreamSection>
            )}
        </>
    );
};

export default withTranslation()(StreamInfo);
