import styled from 'styled-components';

import { mediaQueries } from '../../styles/variables';

export const Section = styled.section`
    display: flex;
    flex-direction: column;

    padding: 1.5rem;

    ${mediaQueries.sm} {
        padding: 2rem 3rem;
    }

    ${mediaQueries.md} {
        padding: 3rem 5rem;
    }

    ${mediaQueries.xxl} {
        padding: 4rem 8rem;
    }
`;
