import { fetchApi } from "../../api/apiBase";

export const multimediaOtherServiceFactory = () => {
    const getOtherGallery = () => fetchApi(`/othergalleries`);

    const getOtherGalleryById = ({ id }) =>
        fetchApi(`/othergalleries?id=${id}`);

    const getOtherPhotos = () => fetchApi(`/otherphotos?_limit=-1`);

    const getOtherAudios = () => fetchApi(`/otheraudios?_limit=-1`);

    const getOtherVideos = () => fetchApi(`/othervideos?_limit=-1`);
    return {
        getOtherGalleryById,
        getOtherGallery,
        getOtherPhotos,
        getOtherAudios,
        getOtherVideos,
    };
};
