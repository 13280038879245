import { fetchApi } from "../../api/apiBase";

export const competitorServiceFactory = () => {
    const getCompetitors = () => fetchApi(`/participants?_limit=-1`);

    const getCompetitorById = id => fetchApi(`/participants/${id}`);

    return {
        getCompetitors,
        getCompetitorById,
    };
};
