import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { getBrowserLanguage } from './helpers';

import aboutUsEn from './static/locales/en/aboutUs';
import aboutUsPl from './static/locales/pl/aboutUs';
import accreditationEn from './static/locales/en/accreditation';
import accreditationPl from './static/locales/pl/accreditation';
import articleEn from './static/locales/en/article';
import articlePl from './static/locales/pl/article';
import authPl from './static/locales/pl/auth';
import authEn from './static/locales/en/auth';
import calendarEn from './static/locales/en/calendar';
import calendarPl from './static/locales/pl/calendar';
import competitionEn from './static/locales/en/competition';
import competitionPl from './static/locales/pl/competition';
import competitionRulesEn from './static/locales/en/competitionRules';
import competitionRulesPl from './static/locales/pl/competitionRules';
import compositionsEN from './static/locales/en/compositions';
import compositionsPL from './static/locales/pl/compositions';
import homeEn from './static/locales/en/home';
import homePl from './static/locales/pl/home';
import jurySelectEn from './static/locales/en/jurySelect';
import jurySelectPl from './static/locales/pl/jurySelect';
import layoutEn from './static/locales/en/layout';
import layoutPl from './static/locales/pl/layout';
import newsEn from './static/locales/en/news';
import newsPl from './static/locales/pl/news';
import multimediaEn from './static/locales/en/multimedia';
import multimediaPl from './static/locales/pl/multimedia';
import partnersEn from './static/locales/en/partners';
import partnersPl from './static/locales/pl/partners';
import pressEn from './static/locales/en/press';
import pressPl from './static/locales/pl/press';
import profileEn from './static/locales/en/profile';
import profilePl from './static/locales/pl/profile';
import rulesEn from './static/locales/en/rules';
import rulesPl from './static/locales/pl/rules';
import ticketsEn from './static/locales/en/tickets';
import ticketsPl from './static/locales/pl/tickets';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['path'],
        },
        resources: {
            en: {
                aboutUs: aboutUsEn,
                accreditation: accreditationEn,
                article: articleEn,
                auth: authEn,
                calendar: calendarEn,
                competition: competitionEn,
                competitionRules: competitionRulesEn,
                compositions: compositionsEN,
                home: homeEn,
                jurySelect: jurySelectEn,
                layout: layoutEn,
                news: newsEn,
                multimedia: multimediaEn,
                partners: partnersEn,
                press: pressEn,
                profile: profileEn,
                rules: rulesEn,
                tickets: ticketsEn,
            },
            pl: {
                aboutUs: aboutUsPl,
                accreditation: accreditationPl,
                article: articlePl,
                auth: authPl,
                calendar: calendarPl,
                competition: competitionPl,
                competitionRules: competitionRulesPl,
                compositions: compositionsPL,
                home: homePl,
                jurySelect: jurySelectPl,
                layout: layoutPl,
                news: newsPl,
                multimedia: multimediaPl,
                partners: partnersPl,
                press: pressPl,
                profile: profilePl,
                rules: rulesPl,
                tickets: ticketsPl,
            },
        },
        fallbackLng: getBrowserLanguage(),
        interpolation: {
            escapeValue: false,
        },
    });

i18n.on('languageChanged', lang => {
    const rest = window.location.pathname.split('/').slice(2);
    window.location.replace(`/${lang}/${rest.join('/')}`);
});

export default i18n;
