import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { switchMap } from 'rxjs/operators';
import { interval, from } from 'rxjs';

import { authService } from './services';
import { getBrowserLanguage } from './helpers';
import { getToken } from './helpers/auth';
import { AuthContext } from './helpers/auth/Context';
import { Routes } from './routes';
import { loadCdnList, drawApiBase, apiBase } from './api/apiBase';

const queryClient = new QueryClient();
let subscription;
let afterFirstLoad = false;
const TIMER_DRAW = 20 * 60 * 1000; // draw every 20 min
const TIMER_RELOAD = 5 * 60 * 1000; // reload every 5 min
let cdnListGlobal = [];
const apiBaseFromEnv = !!apiBase;

export const App = () => {
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [userData, setUserData] = React.useState(null);
    const [cdnListLoaded, setCdnListLoaded] = React.useState(false);
    const [isUserDataUpdate, setIsUserDataUpdate] = React.useState(false);

    React.useEffect(() => {
        let isMounted = true;

        const auth = async () => {
            const token = getToken();

            const response = token && (await authService.getUserData(token));

            if (response) {
                setUserData(response);
                setIsAuthenticated(true);
            } else {
                setUserData(null);
                setIsAuthenticated(false);
            }
        };

        isMounted && setIsUserDataUpdate(false);
        isMounted && auth();

        return () => (isMounted = false);
    }, [isAuthenticated, isUserDataUpdate]);

    //If api base is not set from env => Loads first cdn list, and draw api base
    React.useEffect(() => {
        if (!apiBaseFromEnv) {
            loadCdnList().then(val => {
                const list = val.cdnlist;
                if (list) {
                    drawApiBase(list);
                    cdnListGlobal = list;
                    //Draw new api base every 20 minutes
                    subscription = interval(TIMER_DRAW)
                        .pipe()
                        .subscribe(() => {
                            drawApiBase(cdnListGlobal);
                        });
                    setCdnListLoaded(true);
                }
            });

            //Updates the list of api endpoints every 5 minutes
            const source = interval(TIMER_RELOAD);
            source.pipe(switchMap(() => from(loadCdnList()))).subscribe(val => {
                if (val.cdnlist) {
                    cdnListGlobal = val.cdnlist;
                }
            });
        }
    }, []);

    //Draw api base after route change
    const drawApiBaseLink = () => {
        if (!apiBaseFromEnv) {
            if (afterFirstLoad) {
                drawApiBase(cdnListGlobal);
                subscription.unsubscribe();
                //Draw new api base every 20 minutes (reset interval)
                subscription = interval(TIMER_DRAW)
                    .pipe()
                    .subscribe(() => {
                        drawApiBase(cdnListGlobal);
                    });
            } else {
                afterFirstLoad = true;
            }
        }
    };

    const handleAuthenticatedData = data => {
        setIsAuthenticated(data);
    };

    const handleUserDataUpdate = data => {
        setIsUserDataUpdate(data);
    };

    return (
        <>
            {apiBaseFromEnv || cdnListLoaded ? (
                <QueryClientProvider client={queryClient}>
                    <AuthContext.Provider
                        value={{
                            userData,
                            isAuthenticated,
                            setIsAuthenticated: handleAuthenticatedData,
                            setIsUserDataUpdate: handleUserDataUpdate,
                        }}
                    >
                        <BrowserRouter>
                            <Switch>
                                <Route
                                    path="/:lang"
                                    render={props => (
                                        <Routes {...props} drawApiBaseLink={drawApiBaseLink} />
                                    )}
                                />
                                <Redirect to={getBrowserLanguage()} />
                            </Switch>
                        </BrowserRouter>
                    </AuthContext.Provider>
                </QueryClientProvider>
            ) : (
                ''
            )}
        </>
    );
};
