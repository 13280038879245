import i18n from '../../i18n';
import { login, logout } from '../../helpers/auth';
import { routes } from '../../routes';

export const authServiceFactory = (apiBase, fetch) => {
    const { language } = i18n;

    const POST = value => {
        return {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(value),
        };
    };
    const register = (values, t, history) =>
        fetch(`${apiBase}/auth/register`, POST(values))
            .then(async res => {
                if (res.ok) {
                    history.push({
                        pathname: `/${language}${routes.registerActivate}`,
                        state: { email: values.email },
                    });
                    return res.ok;
                } else {
                    return res.json();
                }
            })
            .catch(() => alert(t('error')));

    const activateAccount = (activationCode, t) =>
        fetch(`${apiBase}/auth/activate`, POST(activationCode))
            .then(res => (res.ok ? res : res.json()))
            .catch(() => alert(t('error')));

    const logIn = (path, value, t) =>
        fetch(path, POST(value))
            .then(async res => {
                const responseJSON = await res.json();

                if (responseJSON.token) {
                    const { token } = responseJSON;
                    login({ token });
                }

                return responseJSON;
            })
            .catch(() => alert(t('error')));

    const deactivateAccount = (token, history) => {
        fetch(`${apiBase}/auth/deactivate`, {
            headers: { Authorization: `Bearer ${token}` },
            method: 'POST',
        })
            .then(res => {
                if (res.ok) {
                    history.push(`/${language}${routes.deactivateAccount}`);
                    logout();
                    window.location.reload(true);
                } else {
                    return res.json();
                }
            })
            .catch(er => Promise.reject(new Error(er)));
    };

    const getUserData = token =>
        fetch(`${apiBase}/auth/me`, {
            headers: { Authorization: `Bearer ${token}` },
        })
            .then(res => res.json())
            .catch(er => Promise.reject(new Error(er)));

    const forgetPassword = (values, history) =>
        fetch(`${apiBase}/auth/forgot-password`, POST(values))
            .then(res => {
                if (res.ok) {
                    history.push(`/${language}${routes.login}${routes.resetPasswordEmail}`);
                } else {
                    return res.json();
                }
            })
            .catch(er => Promise.reject(new Error(er)));

    const resetPassword = (values, history) =>
        fetch(`${apiBase}/auth/reset-password`, POST(values))
            .then(res => {
                if (res.ok) {
                    history.push(`/${language}${routes.login}${routes.resetPasswordSuccess}`);
                } else {
                    return res.json();
                }
            })
            .catch(er => Promise.reject(new Error(er)));

    const resendActivationEmail = (values, history) =>
        fetch(`${apiBase}/auth/resend-mail`, POST(values))
            .then(res => {
                if (res.ok) {
                    history.push(`/${language}${routes.login}${routes.resendEmailSuccess}`);
                } else {
                    return res.json();
                }
            })
            .catch(er => Promise.reject(new Error(er)));

    const changePassword = (values, token, setIsModalActive) =>
        fetch(`${apiBase}/auth/change-password`, {
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(values),
        })
            .then(res => {
                if (res.ok) {
                    return setIsModalActive(true);
                } else {
                    return res.json();
                }
            })
            .catch(er => Promise.reject(new Error(er)));

    return {
        register,
        activateAccount,
        logIn,
        deactivateAccount,
        getUserData,
        forgetPassword,
        resetPassword,
        resendActivationEmail,
        changePassword,
    };
};
