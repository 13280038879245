const aboutUsDropdown = [
    { name: 'aboutUs competition', href: '/about/about-competition' },
    { name: 'aboutUs organizer', href: '/about/organizer' },
    { name: 'aboutUs press', href: '/about/press' },
    { name: 'aboutUs contact', href: '/about/contact' },
    { name: 'aboutUs tickets', href: '/about/tickets' },
];

const juryDropdown = [
    { name: 'jury competition', href: '/jury/jury-competition' },
    { name: 'jury elimination', href: '/jury/jury-elimination' },
    { name: 'selection committee', href: '/jury/jury-selection-committee' },
];

const competitionDropdown = [
    { name: 'competition rules', href: '/competition/rules' },
    { name: 'competition information', href: '/competition/information' },
    { name: 'competition editions', href: '/competition/editions' },
    { name: 'competition awards', href: '/competition/awards' },
];

const multimediaDropdown = [
    { name: 'multimedia competition', href: '/multimedia/competition-transmission' },
    { name: 'multimedia other', href: '/multimedia/other' },
];

export const navigationLinks = [
    { name: 'home', href: '/', isDropdown: false },
    { name: 'news', href: '/news', isDropdown: false },
    {
        name: 'competition',
        href: '/competition/rules',
        isDropdown: true,
        dropdown: competitionDropdown,
    },
    {
        name: 'competitors',
        href: '/competitors',
        isDropdown: false,
    },
    {
        name: 'jury',
        href: '/jury/jury-competition',
        isDropdown: true,
        dropdown: juryDropdown,
    },
    {
        name: 'calendar',
        href: '/calendar',
        isDropdown: false,
    },
    {
        name: 'compositions',
        href: '/compositions',
        isDropdown: false,
    },
    {
        name: 'multimedia',
        href: '/multimedia/competition-transmission',
        isDropdown: true,
        dropdown: multimediaDropdown,
    },
    {
        name: 'about',
        href: '/about/about-competition',
        isDropdown: true,
        dropdown: aboutUsDropdown,
    },
    { name: 'partners', href: '/partners', isDropdown: false },
];

export const facebookLink = 'https://www.facebook.com/instytut.chopina/';

export const instagramLink = 'https://www.instagram.com/chopininstitute/';

export const twitterLink = 'https://twitter.com/ChopinInstitute/';

export const youtubeLink = 'https://www.youtube.com/channel/UCSTXol20Q01Uj-U5Yp3IqFg';

export const chopinMusicSourcesLink = 'https://chopin.musicsources.pl';

export const exhibitionLink =
    'https://artsandculture.google.com/exhibit/mi%C4%99dzynarodowe-konkursy-pianistyczne-im-fryderyka-chopina/1gLy30LrMiGSKQ?hl=pl';
