import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { colors, fontFamily, mediaQueries, fontSize } from '../styles/variables';
import { competitionLogo } from '../static/configs/images';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1.5rem auto;
    max-width: 50rem;
    color: ${colors.black};
    text-align: center;
    font-family: ${fontFamily.alegreya};

    ${mediaQueries.sm} {
        margin: 2rem auto 2.5rem;
    }

    ${mediaQueries.lg} {
        margin: 3.5rem auto 4rem;
    }
`;

const Title = css`
    margin: 1rem 1rem 0;
`;

const NumberTitle = styled.h2`
    ${Title}
    font-size: ${fontSize.extraLargeExtended};

    ${mediaQueries.sm} {
        font-size: ${fontSize.biggest};
    }

    ${mediaQueries.lg} {
        font-size: ${fontSize.offTheScale};
    }
`;

const SubTitle = styled.h3`
    ${Title}
    font-size: ${fontSize.largeExtended};

    ${mediaQueries.sm} {
        font-size: ${fontSize.extraLargeExtended};
    }

    ${mediaQueries.lg} {
        font-size: ${fontSize.biggest};
    }
`;

const Paragraph = styled.p`
    margin: 1.5rem 2rem 2rem;

    ${mediaQueries.sm} {
        font-size: ${fontSize.medium};
    }

    ${mediaQueries.lg} {
        font-size: ${fontSize.mediumExtended};
    }
`;

const Image = styled.img`
    margin: 0 auto;
    width: 10rem;
`;

const Error = ({ t }) => (
    <Wrapper>
        <Image src={competitionLogo} />

        <NumberTitle>{'404'}</NumberTitle>
        <SubTitle>{t('error.subtitle')}</SubTitle>
        <Paragraph>{t('error.paragraph')}</Paragraph>
    </Wrapper>
);

export default withTranslation('layout')(Error);
