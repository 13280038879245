import { backendApiBase } from '../api/apiBase';

import { aboutUsServiceFactory } from './aboutUs';
import { accreditationServiceFactory } from './accreditation';
import { articleServiceFactory } from './article';
import { authServiceFactory } from './auth';
import { authCmsServiceFactory } from './authCms';
import { awardsServiceFactory } from './awards';
import { calendariumServiceFactory } from './calendarium';
import { compositionServiceFactory } from './composition';
import { competitionServiceFactory } from './competition';
import { contactsServiceFactory } from './contact';
import { countriesServiceFactory } from './countries';
import { favoritesServiceFactory } from './favorites';
import { juryServiceFactory } from './jury';
import { likeServiceFactory } from './like';
import { multimediaCompetitionServiceFactory } from './multimediaCompetition';
import { multimediaOtherServiceFactory } from './multimediaOther';
import { newsletterServiceFactory } from './newsletter';
import { participantInfoServiceFactory } from './participantInfo';
import { competitorServiceFactory } from './competitor';
import { partnersServiceFactory } from './partners';
import { personalDataProtectionServiceFactory } from './personalDataProtection';
import { commentServiceFactory } from './comment';
import { streamInfoServiceFactory } from './streamInfo';
import { commentaryRulesServiceFactory } from './commentaryRules';
import { ticketsServiceFactory } from './tickets';
import { privacyPolicyServiceFactory } from './privacyPolicy';
import { footerIframeServiceFactory } from './footerIframe';

export const aboutUsService = aboutUsServiceFactory();
export const accreditationService = accreditationServiceFactory(backendApiBase, fetch);
export const articleService = articleServiceFactory();
export const authService = authServiceFactory(backendApiBase, fetch);
export const authCmsService = authCmsServiceFactory();
export const awardsService = awardsServiceFactory();
export const calendariumService = calendariumServiceFactory();
export const commentService = commentServiceFactory(backendApiBase, fetch);
export const compositionService = compositionServiceFactory();
export const competitionService = competitionServiceFactory();
export const contactService = contactsServiceFactory();
export const countriesService = countriesServiceFactory();
export const favoritesService = favoritesServiceFactory(backendApiBase, fetch);
export const juryService = juryServiceFactory();
export const likeService = likeServiceFactory(backendApiBase, fetch);
export const multimediaCompetitionService = multimediaCompetitionServiceFactory();
export const multimediaOtherService = multimediaOtherServiceFactory();
export const newsletterService = newsletterServiceFactory();
export const participantInfoService = participantInfoServiceFactory();
export const personalDataProtectionService = personalDataProtectionServiceFactory();
export const competitorService = competitorServiceFactory();
export const partnersService = partnersServiceFactory();
export const streamInfoService = streamInfoServiceFactory();
export const commentaryRulesService = commentaryRulesServiceFactory();
export const ticketsService = ticketsServiceFactory();
export const privacyPolicyService = privacyPolicyServiceFactory();
export const footerIframeService = footerIframeServiceFactory();
