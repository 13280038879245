import React from 'react';

export const routesList = [
    {
        exact: true,
        path: '/',
        component: React.lazy(() => import('../pages/index')),
    },
    {
        exact: true,
        path: '/news',
        component: React.lazy(() => import('../pages/news')),
    },
    {
        exact: true,
        path: '/compositions',
        component: React.lazy(() => import('../pages/compositions/compositions')),
    },
    {
        exact: true,
        path: '/compositions/:id/:title',
        component: React.lazy(() => import('../pages/compositions/composition')),
    },
    {
        exact: true,
        path: '/compositions/:id/:title/:category',
        component: React.lazy(() => import('../pages/compositions/multimedia')),
    },
    {
        exact: true,
        path: '/news/article/:id/:title',
        component: React.lazy(() => import('../pages/new')),
    },
    {
        exact: true,
        path: '/jury/jury-competition',
        component: React.lazy(() => import('../pages/jury/competition')),
    },
    {
        exact: true,
        path: '/jury/jury-elimination',
        component: React.lazy(() => import('../pages/jury/elimination')),
    },
    {
        exact: true,
        path: '/jury/jury-selection-committee',
        component: React.lazy(() => import('../pages/jury/selection-committee')),
    },
    {
        exact: true,
        path: '/jury/:id/:name',
        component: React.lazy(() => import('../pages/jury/jury')),
    },
    {
        exact: true,
        path: '/jury/:id/:name/:category',
        component: React.lazy(() => import('../pages/jury/multimedia')),
    },
    {
        exact: true,
        path: '/competition/awards',
        component: React.lazy(() => import('../pages/competition/awards')),
    },
    {
        exact: true,
        path: '/competition/editions',
        component: React.lazy(() => import('../pages/competition/editions')),
    },
    {
        exact: true,
        path: '/competition/information',
        component: React.lazy(() => import('../pages/competition/information')),
    },
    {
        exact: true,
        path: '/competition/rules',
        component: React.lazy(() => import('../pages/competition/rules')),
    },
    {
        exact: true,
        path: '/competitors',
        component: React.lazy(() => import('../pages/competition/competitors')),
    },
    {
        exact: true,
        path: '/competitors/:id/:name',
        component: React.lazy(() => import('../pages/competition/competitor')),
    },
    {
        exact: true,
        path: '/competitors/:id/:name/:category',
        component: React.lazy(() => import('../pages/competition/multimedia')),
    },
    {
        exact: true,
        path: '/about/about-competition',
        component: React.lazy(() => import('../pages/about/competition')),
    },
    {
        exact: true,
        path: '/about/organizer',
        component: React.lazy(() => import('../pages/about/organizer')),
    },
    {
        exact: true,
        path: '/about/press',
        component: React.lazy(() => import('../pages/about/press')),
    },
    {
        exact: true,
        path: '/about/tickets',
        component: React.lazy(() => import('../pages/about/tickets')),
    },
    {
        exact: true,
        path: '/about/contact',
        component: React.lazy(() => import('../pages/about/contact')),
    },
    {
        exact: true,
        path: '/partners',
        component: React.lazy(() => import('../pages/partners')),
    },
    {
        exact: true,
        path: '/calendar',
        component: React.lazy(() => import('../pages/calendar/calendar')),
    },
    {
        exact: true,
        path: '/accreditation',
        component: React.lazy(() => import('../pages/accreditation')),
    },
    {
        exact: true,
        path: '/accreditation/success',
        component: React.lazy(() => import('../pages/accreditation/success')),
    },
    {
        exact: true,
        path: '/calendar/:id/:name',
        component: React.lazy(() => import('../pages/calendar/calendarEvent')),
    },
    { exact: true, path: '/login', component: React.lazy(() => import('../pages/login')) },
    {
        exact: true,
        path: '/login/activate',
        component: React.lazy(() => import('../pages/login/activate')),
    },
    {
        exact: true,
        path: '/login/activate/:code',
        component: React.lazy(() => import('../pages/activate/code')),
    },
    {
        exact: true,
        path: '/login/success',
        component: React.lazy(() => import('../pages/login/success')),
    },
    {
        exact: true,
        path: '/login/reset-password',
        component: React.lazy(() => import('../pages/login/resetPasswordEmail')),
    },
    {
        exact: true,
        path: '/login/reset-password-email',
        component: React.lazy(() => import('../pages/login/resetCheckEmail')),
    },
    {
        exact: true,
        path: '/login/reset-password/:token',
        component: React.lazy(() => import('../pages/login/resetPasswordForm')),
    },
    {
        exact: true,
        path: '/login/reset-password-success',
        component: React.lazy(() => import('../pages/login/resetSuccess')),
    },
    {
        exact: true,
        path: '/login/resend-email',
        component: React.lazy(() => import('../pages/login/resendEmail')),
    },
    {
        exact: true,
        path: '/login/resend-mail-success',
        component: React.lazy(() => import('../pages/login/resendEmailSuccess')),
    },
    {
        exact: true,
        path: '/profile',
        component: React.lazy(() => import('../pages/profile')),
    },
    {
        exact: true,
        path: '/profile/favorite-competitors',
        component: React.lazy(() => import('../pages/profile/competitors')),
    },
    {
        exact: true,
        path: '/profile/favorite-multimedia',
        component: React.lazy(() => import('../pages/profile/multimedia')),
    },
    {
        exact: true,
        path: '/profile/sessions',
        component: React.lazy(() => import('../pages/profile/sessions')),
    },
    {
        exact: true,
        path: '/profile/change-password',
        component: React.lazy(() => import('../pages/profile/changePassword')),
    },
    {
        exact: true,
        path: '/multimedia/other',
        component: React.lazy(() => import('../pages/multimedia/other')),
    },
    {
        exact: true,
        path: '/multimedia/other/:id/:gallery',
        component: React.lazy(() => import('../pages/multimedia/other/gallery')),
    },
    {
        exact: true,
        path: '/multimedia/competition-transmission',
        component: React.lazy(() => import('../pages/multimedia/competition')),
    },
    {
        exact: true,
        path: '/multimedia/competition-transmission/:id/:gallery',
        component: React.lazy(() => import('../pages/multimedia/competition/gallery')),
    },
    {
        exact: true,
        path: '/profile/close',
        component: React.lazy(() => import('../pages/profile/close')),
    },
    {
        exact: true,
        path: '/profile/success',
        component: React.lazy(() => import('../pages/profile/success')),
    },
];
