import React from 'react';
import styled from 'styled-components';

import { withTranslation } from 'react-i18next';
import { colors, mediaQueries, fontSize } from '../../styles/variables';
import { useGetPromise } from '../../hooks/usePromise';
import { privacyPolicyService } from '../../services';
import { fileUrl, translateDynamic } from '../../helpers';

const Wrapper = styled.section`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: auto;
    padding: 0.5rem;

    color: ${colors.aluminum};
    border-top: 1px ${colors.doveGray} solid;

    font-size: ${fontSize.small};

    ${mediaQueries.sm} {
        font-size: ${fontSize.normal};
        padding: 0.5rem 3rem;
    }

    ${mediaQueries.md} {
        padding: 0.5rem 5rem;
        flex-direction: row;
        justify-content: space-between;
    }

    ${mediaQueries.xxl} {
        padding: 0.5rem 8rem;
    }
`;

const PrivacyPolicyLink = styled.a`
    font-size: inherit;
    color: #000;
    padding-bottom: 0.5rem;

    ${mediaQueries.md} {
        padding-bottom: 0rem;
    }
`

const MarkFooterContainer = ({ t, i18n }) => {
    const translate = translateDynamic(i18n);
    const [privacyPolicy] = useGetPromise(privacyPolicyService.getPrivacyPolicy, null, true);

    return (
        <Wrapper>
            <div>© Narodowy Instytut Fryderyka Chopina 2020</div>
            {privacyPolicy && privacyPolicy[0] &&
                <PrivacyPolicyLink
                    href={fileUrl(translate(privacyPolicy[0].file_en.url, privacyPolicy[0].file_pl.url))}
                    target="_blank"
                >
                    {translate(privacyPolicy[0].title_en, privacyPolicy[0].title_pl)}
                </PrivacyPolicyLink>
            }
        </Wrapper>
    )
}

export const MarkFooter = withTranslation('layout')(MarkFooterContainer);
